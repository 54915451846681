import { Injectable } from '@angular/core';
import { Observable, map, mergeMap } from 'rxjs';
import { GenericHelper } from 'src/app/core/helpers/generic-helper.class';
import { FileLocationDetails } from 'src/app/core/models/file-upload.interface';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { UrlNames } from 'src/app/core/services/urlProfiler';
import { BaseWebApiService } from 'src/app/core/services/_base-web-api.service';
import {
	courseTestListParams,
	courseTestParams,
	Test,
} from 'src/app/shared/models/create-test';
import {
	CourseTest,
	EditTestDetails,
	GetTestParams,
} from '../_models/create-resource.model';
import { EncryptionService } from 'src/app/core/services/encryption.service';

@Injectable({
	providedIn: 'root',
})
export class CourseTestService {
	constructor(
		private apiService: BaseWebApiService,
		private localData: LocalDataService,
		private encryptionService: EncryptionService
	) {}

	public getCourseTestList(params: courseTestListParams): Observable<any> {
		return this.apiService.get(UrlNames.courseTests, params);
	}

	public getCourseTest(params: courseTestListParams): Observable<any> {
		return this.apiService.get(UrlNames.getCourseTest, params);
	}

	public SaveCourse(
		testPayload: Array<Test>,
		params: courseTestParams,
		isLp: boolean
	): Observable<any> {
		return this.apiService.post(
			`${UrlNames.course}${
				isLp ? UrlNames.addLpTests : UrlNames.addCourseTest
			}`,
			testPayload,
			params
		);
	}

	public getPresignedUrl(path: string): Observable<FileLocationDetails> {
		return this.apiService.get(`${UrlNames.getSecretKey}`, {}).pipe(
			mergeMap((response: any) => {
				let key = response?.secretKey;
				return this.apiService
					.post(`${UrlNames.v2myCourses}${UrlNames.takeCourse}`, {
						path: this.encryptionService.encrypt(path, key),
						duration: this.encryptionService.encrypt('', key),
					})
					.pipe(map((res: FileLocationDetails) => res));
			})
		);
	}

	public getResourceTestList(params: GetTestParams): Observable<any> {
		return this.apiService.get(`${UrlNames.courseOrLp}${UrlNames.tests}`, {
			...params,
			locationId: this.localData.getLocationId(),
		});
		// .pipe(
		// 	map((x: CourseTest[]) => {
		// 		return x.map((course) => {
		// 			return {
		// 				...course,
		// 				questionList:
		// 					course.questionList &&
		// 					course.questionList.map((question) => {
		// 						return {
		// 							...question,
		// 							question:
		// 								question.question &&
		// 								GenericHelper.stripHTML(
		// 									question.question
		// 								),
		// 							options:
		// 								question.options &&
		// 								question.options.map((option) => {
		// 									return {
		// 										...option,
		// 										option:
		// 											option.option &&
		// 											GenericHelper.stripHTML(
		// 												option.option
		// 											),
		// 									};
		// 								}),
		// 						};
		// 					}),
		// 			};
		// 		});
		// 	})
		// );
	}
	public updateResourceTests(payload: EditTestDetails): Observable<any> {
		return this.apiService.put(
			`${UrlNames.tests}${UrlNames.edit}`,
			payload
		);
	}

	public deleteTest(id: number): Observable<any> {
		return this.apiService.delete(
			`${UrlNames.deleteTest}`,
			undefined,
			undefined,
			undefined,
			{
				testIds: id,
				userId: this.localData.getUserId(),
				locationId: this.localData.getLocationId(),
			}
		);
	}
}
