<div
	class="thread"
	[class]="{ selected: isSelected && !isOnPopup }"
>
	<div class="con">
		<div class="d-flex gap-3 w-100">
			<img src="../../../../../../assets/images/profile-img.png" alt="" />
			<div class="d-flex flex-column w-100">
				<div class="d-flex justify-content-between">
					<h5>
						{{ name }}
					</h5>
					<h6>{{ lastMessageTime }}</h6>
				</div>
				<h6>{{ lastMessage }}</h6>
			</div>
		</div>
	</div>
</div>
