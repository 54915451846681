import { Injectable } from '@angular/core';
import { LocalStorageKey } from 'src/app/core/models/local-storage-key.enum';
import { BaseWebApiService } from 'src/app/core/services/_base-web-api.service';
import { UrlNames } from 'src/app/core/services/urlProfiler';
import { catchError, map, mergeMap, Observable, of, take, tap } from 'rxjs';
import { UserPrivilageInterface } from 'src/app/core/models/user-privilege.interface';
import {
	LoginToekInterface,
	UserLoginInterface,
} from 'src/app/core/models/user-details.interface';
import { select, Store } from '@ngrx/store';
import {
	getUserPrivileges,
	SharedState,
} from 'src/app/shared/_state/shared.reducer';
import { EncryptionService } from 'src/app/core/services/encryption.service';
import { LinkedUserLoginRequestInterface } from '../_models/linked-user-login.interface';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		private apiService: BaseWebApiService,
		private encryptionService: EncryptionService,
		private sharedStore: Store<SharedState>
	) {}

	public login(loginPayload: UserLoginInterface): Observable<Object> {
		let encryptedPayload = {
			userName: this.encryptionService.encode(loginPayload.userName),
			password: this.encryptionService.encode(loginPayload.password),
			newPassword: loginPayload?.newPassword
				? this.encryptionService.encode(loginPayload.newPassword)
				: ' ',
			rememberSiteCode: loginPayload?.rememberSiteCode,
		};
		return this.apiService.post(
			`${UrlNames.newUserLogin}`,
			encryptedPayload
		);
	}

	/**
	 * 	 TODO: Implement Logout when backend is ready.
	 *   Right now just clearing the localstorage
	 *   and directing to login page
	 */
	public logout(
		userSessionId: number,
		locationId: number,
		accessToken: string
	): Observable<any> {
		return this.apiService.post(`${UrlNames.logoutNewUserSession}`, {
			userSessionId,
			locationId,
			accessToken,
		});
	}

	public forgotPassword(username: string): Observable<Object> {
		return this.apiService.post(UrlNames.forgotPassword, {
			userName: this.encryptionService.encode(username),
		});
	}

	public confirmForgotPassword(payload: {
		password: string;
		userName: string;
		verificationCode: number;
	}): Observable<Object> {
		let encryptedPayload = {
			userName: this.encryptionService.encode(payload.userName),
			password: this.encryptionService.encode(payload.password),
			verificationCode: payload.verificationCode,
		};
		return this.apiService.post(
			`${UrlNames.forgotPasswordConfirmation}`,
			encryptedPayload
		);
	}

	/**
	 * Change Password while user is logged in.
	 */

	public changePassword(payload: {
		token: string;
		op: string;
		np: string;
	}): Observable<Object> {
		return this.apiService.get(`${UrlNames.getSecretKey}`, {}).pipe(
			mergeMap((response: any) => {
				let key = response?.secretKey;
				const body = {
					accessToken: payload.token,
					newPassword: this.encryptionService.encrypt(
						payload.np,
						key
					),
					oldPassword: this.encryptionService.encrypt(
						payload.op,
						key
					),
				};
				return this.apiService
					.post(`${UrlNames.changePassword}`, body)
					.pipe(
						tap((x) => {
							if (x['error']) catchError(this.handleError);
						})
					);
			})
		);
	}
	public changeUserPassword(payload: {
		newPassword: string;
		resetToken: string;
	}): Observable<Object> {
		const body = {
			newPassword: this.encryptionService.encode(payload.newPassword),
			resetToken: payload.resetToken,
		};
		return this.apiService
			.post(`${UrlNames.changeUserPassword}`, body)
			.pipe(
				tap((x) => {
					if (x['error']) catchError(this.handleError);
				})
			);
	}

	/**
	 *   TODO: Implement Refresh Token
	 *   for better session management and security
	 */
	public isLoggedIn(): boolean {
		let isSessionValid = false;
		const savedUser = localStorage.getItem(LocalStorageKey.TOKEN);
		isSessionValid = savedUser == undefined || savedUser == null;
		return !isSessionValid;
	}

	public getUserPrivileges(
		username: string,
		clientCode: string
	): Observable<UserPrivilageInterface> {
		return this.apiService.get(`${UrlNames.newprivileges}/${username}`, {
			clientCode: clientCode,
		});
	}

	public getUserInfoFromCognito(token: string): Observable<any> {
		return this.apiService.get(
			UrlNames.oauth2,
			null,
			'cognito',
			undefined,
			undefined,
			undefined,
			{
				Authorization: `Bearer ${token}`,
			}
		);
	}

	public getUserSites(username: string): Observable<any> {
		return this.apiService.get(
			`${UrlNames.getUserSites}/${username}`,
			null
		);
	}

	private handleError(error: any): string {
		let errorMessage: string = '';

		switch (error) {
			case error.includes('expired'):
				errorMessage =
					'Internal Server Error: Please logout and try again.';
		}

		return errorMessage;
	}

	public trackUserSessionInfo(payload: {
		ipAddress: string;
		locationId: number;
		userId: number;
	}): Observable<any> {
		return this.apiService
			.post(`${UrlNames.sessionTracking}`, payload)
			.pipe(map((res) => res['data'][0]));
	}

	public loadUserEmail(username: string): Observable<boolean> {
		return this.apiService
			.get(`${UrlNames.loadUserEmail}`, {
				username: username,
			})
			.pipe(
				map((x) => x['data'][0]),
				map((x) => {
					if (x.email == null || !x.email.length) return false;
					return true;
				}),
				catchError((err) => {
					if (
						err &&
						err.error &&
						err.error.status == 404 &&
						err.error.message == 'No user found'
					)
						return of(null);
					return of(err);
				})
			);
	}

	public getRefreshToken(token: string): Observable<LoginToekInterface> {
		return this.apiService
			.post(`${UrlNames.refreshSession}`, {
				refreshToken: token,
			})
			.pipe(
				map((x: LoginToekInterface) => {
					return {
						accessToken: x['accessToken'],
						idToken: null,
						expiresIn: x['expiresIn'],
						tokenType: x['tokenType'],
						refreshToken: x['refreshToken'],
					};
				})
			);
	}

	public isSecurityQuestionSetupForCurrentUser(
		username: string
	): Observable<boolean> {
		// enable after integration is complete
		return this.apiService
			.get(`${UrlNames.isUserHavingSecurityQuestionsOrNot}`, { username })
			.pipe(
				map((res) => {
					return (
						res &&
						res['data'] &&
						res['data'][0] &&
						res['data'][0]['userHaveSecurityQuestions']
					);
				})
			);
	}

	public loadUserSavedSecurityQuestions(
		username: string
	): Observable<Array<number>> {
		return this.apiService
			.get(`${UrlNames.loadUserSavedSecurityQuestions}`, { username })
			.pipe(
				map((res) => {
					if (res && res['data']) {
						return res['data'].map((x) => x.questionId);
					}
					return res;
				}),
				catchError((error) => {
					if (error.error && error.error.status == 404) {
						return of([]);
					}
					return of(null);
				})
			);
	}

	public loadAvailableSecurityQuestions(): Observable<any> {
		return this.apiService
			.get(`${UrlNames.loadAvailableSecurityQuestions}`, null)
			.pipe(
				map((res) => {
					return res && res['data'];
				})
			);
	}
	public saveUsersSecurityQuestions(payload: any): Observable<any> {
		return this.sharedStore.pipe(
			select(getUserPrivileges),
			take(1),
			mergeMap((privileges) => {
				return this.apiService.post(
					`${UrlNames.saveUsersSecurityQuestions}`,
					{
						data: payload,
					},
					{
						username: `${privileges.siteCode}.${privileges.userName}`,
					}
				);
			})
		);
	}
	public isUserFirstTimeLogin(): Observable<any> {
		return this.apiService.get(`${UrlNames.isUserFirstTimeLogin}`, null);
	}

	public checkUsersSecurityQuestionsAnswers(
		payload: any,
		username: string
	): Observable<any> {
		return this.sharedStore
			.pipe(
				select(getUserPrivileges),
				take(1),
				mergeMap((privileges) => {
					return this.apiService.post(
						`${UrlNames.checkUsersSecurityQuestionsAnswers}`,
						{ data: payload },
						{
							username,
						}
					);
				})
			)
			.pipe(
				map((res) => {
					return res && res['data'] && res['data'][0];
				})
			);
	}
	public isFirstTimeLogin(username: string): Observable<boolean> {
		return this.apiService
			.get(`${UrlNames.isUserFirstTimeLogin}`, {
				username,
			})
			.pipe(
				map((res) => {
					return (
						res &&
						res['data'] &&
						res['data'][0] &&
						res['data'][0]['firstUser']
					);
				})
			);
	}

	public resetPasswordViaSecurityQuestions(payload): Observable<any> {
		let encryptedPayload = {
			notifyPasswordChange: payload.notifyPasswordChange,
			password: this.encryptionService.encode(payload.password),
			username: this.encryptionService.encode(payload.username),
			verificationCode: payload.verificationCode,
		};
		return this.apiService.put(
			`${UrlNames.changePasswordAfterSecurityQuestion}`,
			encryptedPayload
		);
	}

	public fetchIdToken(): Observable<any> {
		return this.apiService.get(`${UrlNames.fetchIdToken}`, {});
	}

	public linkedUserLogin(payload: LinkedUserLoginRequestInterface) {
		return this.apiService.post(`${UrlNames.linkedUserLogin}`, payload);
	}
}
