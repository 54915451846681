import { Pipe, ElementRef, Renderer2, PipeTransform } from '@angular/core';

@Pipe({
	name: 'scormResponse',
})
export class ScormResponsePipe implements PipeTransform {
	constructor(private el: ElementRef, private renderer: Renderer2) {}

	transform(value: string): string {
		if (!value) return value;

		return value
			.split('_')
			.map((word, index) => {
				return index === 0
					? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
					: word.toLowerCase();
			})
			.join(' ');
	}
}
