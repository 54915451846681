<div [formGroup]="fgName">
	<mat-form-field appearance="outline">
		<mat-label>{{ label | translate }}</mat-label>
		<input
			matInput
			[formControlName]="controlName"
			[matDatepicker]="picker"
			(change)="setErrorMessage()"
			[placeholder]="'MM-DD'"
		/>
		<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
		<mat-datepicker #picker></mat-datepicker>
		<mat-error *ngIf="fgName.get(controlName).invalid">{{
			errorMessage
		}}</mat-error>
	</mat-form-field>
</div>
