export enum SettingsMap {
	force_change_password_new_users = 'force_change_password_new_users',
	show_mycourses_time_totals = 'show_mycourses_time_totals',
	use_evaluation = 'use_evaluation',
	my_courses_hide_future_override = 'my_courses_hide_future_override',
	my_courses_view_next_year = 'my_courses_view_next_year',
	my_courses_hide_future = 'my_courses_hide_future',
	my_courses_hide_future_threshold = 'my_courses_hide_future_threshold',
	use_mandatory_email = 'use_mandatory_email',
	use_mandatory_categories = 'use_mandatory_categories',
	regular_show_library = 'regular_show_library',
	restricted_show_library = 'restricted_show_library',
	regular_show_manual = 'regular_show_manual',
	allow_questions_on_verification = 'allow_questions_on_verification',
	users_printtest = 'users_printtest',
	use_parent_lock = 'use_parent_lock',
	default_pass_score = 'default_pass_score',
	default_test_attempts = 'default_test_attempts',
	remove_records = 'remove_records',
	my_courses_check_previous_year = 'my_courses_check_previous_year',
	use_timed_completion = 'use_timed_completion',
	use_timed_completion_bypass = 'use_timed_completion_bypass',
	allow_link_in = 'allow_link_in',
	allow_link_out = 'allow_link_out',
	client_education_year = 'client_education_year',
	hide_required_before_date_entered = 'hide_required_before_date_entered',
	check_previous_on_users_added_this_year = 'check_previous_on_users_added_this_year',
	footer_background_color = 'footer_background_color',
	footer_color = 'footer_color',
	header_background_color = 'header_background_color',
	header_color = 'header_color',
	custom_certificate = 'custom_certificate',
	custom_logo = 'custom_logo',
	inside_certificate_logo = 'inside_certificate_logo',
	default_language = 'default_language',
}

export enum BrandSettingsMapForAPI {
	footer_background_color = <any>'footerColor',
	footer_color = <any>'footerFontColor',
	header_background_color = <any>'headerColor',
	header_color = <any>'headerFontColor',
	custom_certificate = <any>'certificateLogo',
	custom_logo = <any>'customLogo',
	inside_certificate_logo = <any>'insideCertificateLogo',
	default_language = <any>'default_language',
}
