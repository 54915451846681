import { Action, createAction, props } from '@ngrx/store';
import { UserPrivilageInterface } from 'src/app/core/models/user-privilege.interface';
import { UserLocationAPIInterface } from 'src/app/modules/manage-users/_models/new-user-.model';
import { LocationList } from '../models/locations';
import { PESurveySubmitINterface } from '../models/program-evaluation-survey.model';
import { Settings } from '../models/settings';

export enum SharedActionTypes {
	GetLocationInfo = '[shared] get location info',
	SaveLocationInfo = '[shared]  location info',
	SavePrivilegeInfo = '[shared] privilege info',

	GetLocationSettings = '[shared] get location settings',
	SaveLocationSettings = '[shared] save location settings',
	UpdateLocationSettings = '[shared] update location settings',

	GetProductAccessSettings = '[shared] get product access settings',
	SaveProductAccessSettings = '[shared] save product access settings',

	GetLocationData = '[shared] get location data',
	SaveLocationData = '[shared] save locationdata',

	GetUserProfileS3Url = '[shared] get user profile s3 url',
	SaveUserProfileS3Url = '[shared] save user profile s3 url',
	UpdateUserProfileS3Url = '[shared] update user profile s3 url',

	UpdateSeletecLocation = '[shared] update selected location',
	GetLocationTree = '[shared] get location tree',
	SaveLocationTree = '[shared] save location tree',

	InitLanguage = '[shared] init language',
	ChangeLanguage = '[shared] change language',
	GetLinkedProfiles = '[shared] get linked profiles',

	OpenPE = '[pe] Open program evaluation popup',
	ClosePE = '[pe] Close program evaluation popup',
	UpdatePEValue = '[pe] update program evaluation value', // update the number after every course
	SavePEValue = '[pe] save program evaluation value',
	SubmitPESurvey = '[pe] submit pe survey',

	SAVE_ROUTING_QUERY_PARAMS = '[app routing] save routing query params',

	OPEN_TUTORIAL_POPUP = '[new user tutorial] open tutorial popup',
	OPEN_EXPIRED_LINK_POPUP = '[new user tutorial] open expired link popup',
}

export const getLocationInfo = createAction(
	SharedActionTypes.GetLocationInfo,
	props<{ root?: boolean }>()
);

export const saveUserLocation = createAction(
	SharedActionTypes.SaveLocationInfo,
	props<{ payload: LocationList }>()
);

export const getLocationSettings = createAction(
	SharedActionTypes.GetLocationSettings,
	props<{ locationId: number; userId: number }>()
);

export const getProductAccessSettings = createAction(
	SharedActionTypes.GetProductAccessSettings,
	props<{ clientId: number }>()
);

export const saveLocationSettings = createAction(
	SharedActionTypes.SaveLocationSettings,
	props<{ payload: Settings[] }>()
);
export const updateLocationSettings = createAction(
	SharedActionTypes.UpdateLocationSettings,
	props<{ payload: any[] }>()
);

export const saveProductAccessSettings = createAction(
	SharedActionTypes.SaveProductAccessSettings,
	props<{ payload: any }>()
);

export const saveUserPrivilege = createAction(
	SharedActionTypes.SavePrivilegeInfo,
	props<{ payload: UserPrivilageInterface }>()
);

export const updateSelectedLocation = createAction(
	SharedActionTypes.UpdateSeletecLocation,
	props<{
		id: number;
		name: string;
		sitecode: string;
		dp1: number;
		dp2?: number;
	}>()
);

export const getLinkedProfiles = createAction(
	SharedActionTypes.GetLinkedProfiles,
	props<{ userId: number }>()
);

export const getLocationDataFromServer = createAction(
	SharedActionTypes.GetLocationData
);

export const getLocationTreeFromServer = createAction(
	SharedActionTypes.GetLocationTree
);

export const saveLocationData = createAction(
	SharedActionTypes.SaveLocationData,
	props<{ location: UserLocationAPIInterface }>()
);

export const saveLocationTree = createAction(
	SharedActionTypes.SaveLocationTree,
	props<{ location: UserLocationAPIInterface }>()
);

export const initLang = createAction(SharedActionTypes.InitLanguage);
export const changeLang = createAction(
	SharedActionTypes.ChangeLanguage,
	props<{ lang: string }>()
);
export const getProfilePicS3URL = createAction(
	SharedActionTypes.GetUserProfileS3Url,
	props<{ picturePath: string }>()
);
export const saveProfilePicS3URL = createAction(
	SharedActionTypes.SaveUserProfileS3Url,
	props<{ s3path: string }>()
);
export const updateProfilePicS3URL = createAction(
	SharedActionTypes.UpdateUserProfileS3Url,
	props<{ s3path: string }>()
);

export const updatePEValue = createAction(
	SharedActionTypes.UpdatePEValue,
	props<{ courseId: number }>()
);
export const savePEValue = createAction(
	SharedActionTypes.SavePEValue,
	props<{ value: number; courseId: number }>()
);
export const openPE = createAction(
	SharedActionTypes.OpenPE,
	props<{ courseId: number }>()
);
export const closePE = createAction(SharedActionTypes.ClosePE);
export const submitPESurvey = createAction(
	SharedActionTypes.SubmitPESurvey,
	props<{ payload: PESurveySubmitINterface }>()
);
export const saveRoutingQueryParams = createAction(
	SharedActionTypes.SAVE_ROUTING_QUERY_PARAMS,
	props<{ path?: any[]; params?: {} }>()
);

export const openTutorialPopup = createAction(
	SharedActionTypes.OPEN_TUTORIAL_POPUP,
	props<{
		beforeLogin: boolean;
		showExpiryPopup?: boolean;
		contLoginFlow?: { privilege: any; user: any };
	}>()
);
export const showExpiredLinkPopup = createAction(
	SharedActionTypes.OPEN_EXPIRED_LINK_POPUP,
	props<{ newUser?: boolean }>()
);
