import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	Resolve,
	RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { forkJoin, map } from 'rxjs';
import { SurveyService } from 'src/app/modules/survey/_services/survey.service';
import { initLang, saveUserLocation } from './shared.actions';
import { SharedState } from './shared.reducer';
@Injectable({
	providedIn: 'root',
})
export class PrivateLayoutResolver implements Resolve<any> {
	constructor(
		private surveyService: SurveyService,
		private sharedStore: Store<SharedState>
	) {}
	resolve() {
		// this.sharedStore.dispatch(getLocationDataFromServer());
		this.sharedStore.dispatch(initLang());
		return forkJoin([this.surveyService.getLocationsList(true)]).pipe(
			map((result) => {
				this.sharedStore.dispatch(
					saveUserLocation({ payload: result[0] })
				);
				return result;
			})
		);
	}
}
