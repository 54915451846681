import { Component, OnInit,Input } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {
  @Input() public Monthdata: any;
  @Input() public dataarray: any;
  chart: any;
  linechart:any;

  ngOnInit(): void {
    this.getlinedata();
  }
  ngOnChanges(changes: any) {
    this.getlinedata();
  }
  getlinedata(){
    if (this.linechart) this.linechart.destroy();
    let ctx: any = document.getElementById('lineChart') as HTMLElement;
    var data = {
      labels:  this.Monthdata,
      datasets: this.dataarray
    };

    //options
    var options = {
      responsive: true,
      title: {
        display: true,
        position: 'top',
        text: 'Line Graph',
        fontSize: 18,
        fontColor: '#111',
      },
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          fontColor: '#333',
          fontSize: 16,
        },
      },
    };

    this.linechart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: options,
    });

  }

}
