import {
	createFeatureSelector,
	createReducer,
	createSelector,
	on,
} from '@ngrx/store';
import { UserPrivilageInterface } from 'src/app/core/models/user-privilege.interface';
import { UserLocationAPIInterface } from 'src/app/modules/manage-users/_models/new-user-.model';
import {
	DataInterface,
	NullDataInterface,
} from 'src/app/state/state-entities/data-interface.model';
import { LocationList } from '../models/locations';
import { Settings } from '../models/settings';
import {
	changeLang,
	saveLocationData,
	saveLocationTree,
	savePEValue,
	saveProfilePicS3URL,
	saveUserLocation,
	saveLocationSettings,
	saveProductAccessSettings,
	saveUserPrivilege,
	updateSelectedLocation,
	saveRoutingQueryParams,
} from './shared.actions';

export interface SharedState {
	locationList: DataInterface<LocationList> | DataInterface<null>; //contains ed groups
	locationData: UserLocationAPIInterface;
	locationTree: UserLocationAPIInterface;
	userPrivileges: DataInterface<UserPrivilageInterface> | DataInterface<null>;
	settings: Settings[];
	productAccessSettings: any;
	locationType: 'grandparent' | 'parent' | 'child';
	selectedLocation: {
		id: number;
		name: string;
		sitecode: string;
	};
	selectedValues: {
		dp1: number;
		dp2?: number;
	};
	lang: string;
	picturePath: string;
	peValue: { cid: number; value: number };
	routeQueryParams: {
		path: any[];
		params: any;
	};
}

export const initialSharedState: SharedState = {
	locationList: NullDataInterface,
	locationData: null,
	locationTree: null,
	userPrivileges: NullDataInterface,
	locationType: 'child',
	selectedLocation: null,
	settings: null,
	productAccessSettings: null,
	selectedValues: {
		dp1: null,
		dp2: null,
	},
	lang: 'en',
	picturePath: null,
	peValue: null,
	routeQueryParams: {
		params: null,
		path: null,
	},
};

const getSharedState = createFeatureSelector<SharedState>('shared');

export const sharedReducer = createReducer(
	initialSharedState,
	on(saveUserLocation, (state, action) => {
		return {
			...state,
			locationList: {
				...state.locationList,
				isLoaded: true,
				data: action.payload,
			},
		};
	}),
	on(saveLocationSettings, (state, action) => {
		return {
			...state,
			settings: action.payload,
		};
	}),
	on(saveProductAccessSettings, (state, action) => {
		return {
			...state,
			productAccessSettings: action.payload,
		};
	}),
	on(saveLocationData, (state, action) => {
		let type: 'grandparent' | 'parent' | 'child';
		const loc = action.location;
		if (loc.grandParent) {
			type = 'grandparent';
		}
		if (loc.parent) {
			type = 'parent';
		}
		if (loc.child) {
			type = 'child';
		}

		return {
			...state,
			locationData: action.location,
			locationType: type,
			// selectedLocation: selected,
		};
	}),
	on(saveLocationTree, (state, action) => {
		return {
			...state,
			locationTree: action.location,
		};
	}),
	on(saveUserPrivilege, (state, action) => {
		const p = action.payload as UserPrivilageInterface;
		return {
			...state,
			userPrivileges: {
				...state.userPrivileges,
				isLoaded: true,
				data: p,
			},
		};
	}),
	on(updateSelectedLocation, (state, action) => {
		return {
			...state,
			selectedLocation: {
				id: action.id,
				name: action.name,
				sitecode: action.sitecode,
			},
			selectedValues: {
				dp1: action.dp1,
				dp2: action.dp2,
			},
		};
	}),
	on(changeLang, (state, action) => {
		return {
			...state,
			lang: action.lang,
		};
	}),
	on(saveProfilePicS3URL, (state, action) => {
		return {
			...state,
			picturePath: action.s3path,
		};
	}),
	on(savePEValue, (state, action) => {
		return {
			...state,
			peValue: { cid: action.courseId, value: action.value },
		};
	}),
	on(saveRoutingQueryParams, (state, action) => {
		return {
			...state,
			routeQueryParams: {
				params: action.params ?? null,
				path: action.path ?? null,
			},
		};
	})
);

export const getProfilePic = createSelector(
	getSharedState,
	(state) => state.picturePath
);
export const getUserLocation = createSelector(
	getSharedState,
	(state) => state.locationList.data
);
export const getAppLanguage = createSelector(
	getSharedState,
	(state) => state.lang
);

export const getLocationData = createSelector(
	getSharedState,
	(state) => state.locationData
);

export const getLocationTree = createSelector(
	getSharedState,
	(state) => state.locationTree
);

export const getUserId = createSelector(
	getSharedState,
	(state) => state.userPrivileges?.data?.userId
);

export const getCurrentChatUser = createSelector(getSharedState, (state) => {
	return {
		email: state.userPrivileges.data.emailId,
		name: state.userPrivileges.data.displayName,
		profilePicUrl: state.userPrivileges.data.picturePath,
		userId: state.userPrivileges.data.userId,
	};
});
export const getUserPrivileges = createSelector(
	getSharedState,
	(state) => state.userPrivileges.data
);

export const getModuleAccess = createSelector(getSharedState, (state) => {
	if (state && state.userPrivileges && state.userPrivileges.data)
		return state.userPrivileges.data.privileges;
	return null;
});
export const getUserClass = createSelector(getSharedState, (state) => {
	if (state && state.userPrivileges && state.userPrivileges.data)
		return state.userPrivileges.data.userClassName;
	return null;
});

export const getRootLocationId = createSelector(getSharedState, (state) => {
	return state.userPrivileges?.data?.locationId;
});

export const getSelectedLocation = createSelector(getSharedState, (state) => {
	return {
		type: state.locationType,
		location: state.selectedLocation,
	};
});

export const getSelectedSiteCode = createSelector(
	getSharedState,
	(state) => state.selectedLocation.sitecode
);

export const getLocationType = createSelector(
	getSharedState,
	(state) => state.locationType
);

export const getDPValues = createSelector(
	getSharedState,
	(state) => state.selectedValues
);

export const getLinkedUsers = createSelector(
	getSharedState,
	(state) =>
		state.userPrivileges &&
		state.userPrivileges.data &&
		state.userPrivileges.data.userLinked && {
			current: `${state.userPrivileges.data.siteCode}.${state.userPrivileges.data.userName}`,
			linked: state.userPrivileges.data.userLinked,
			linkedUsers: state.userPrivileges.data.linkedUsers,
		}
);

export const getPEValue = createSelector(
	getSharedState,
	(state) => state.peValue
);

export const getSettings = createSelector(
	getSharedState,
	(state) => state.settings
);

export const getProductAccessSettings = createSelector(
	getSharedState,
	(state) => state.productAccessSettings
);

export const getSettingValueById = (...value: string[]) =>
	createSelector(getSharedState, (state) => {
		let reqSettings: any[] = [];
		if (!state.settings) return null;
		value.forEach((v) => {
			const setting = state.settings.find((x) => x.name === v);
			if (!setting) reqSettings.push(null);
			if (
				setting &&
				(setting.type == 'checkbox' || setting.type == 'check')
			) {
				reqSettings.push(
					setting.value === 1 || setting.value === '1' ? true : false
				);
			}
			if (setting && (setting.type == 'text' || setting.type == 'date')) {
				reqSettings.push(setting.value);
			}
		});
		return reqSettings;
	});

export const getBrandingSettingsByName = (...value: string[]) =>
	createSelector(getSharedState, (state) => {
		let reqSettings: any[] = [];
		if (!state.settings) return null;
		value.forEach((v) => {
			const setting = state.settings.find((x) => x.name === v);
			if (setting) {
				reqSettings.push(setting.value);
			}
		});
		return reqSettings;
	});

export const getRouteQueryParams = createSelector(getSharedState, (state) => {
	return state.routeQueryParams;
});
