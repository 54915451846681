import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE,
} from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { ANNIVERSARY_DATE_FORMAT } from 'src/app/core/directives/date-input-format.directive';
import { ValidatorMessage } from 'src/app/core/models/validation-messages.model';
import { ErrorMessageService } from 'src/app/core/services/error-message.service';

@Component({
	selector: 'anniversary-date-input',
	templateUrl: './anniversary-date-input.component.html',
	styleUrls: ['./anniversary-date-input.component.scss'],
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE],
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: ANNIVERSARY_DATE_FORMAT,
		},
	],
})
export class AnniversaryDateInputComponent implements OnInit {
	@Input() public fgName: FormGroup;
	@Input() public controlName: string;
	@Input() public placeholder: string = 'MM-DD';
	@Input() public label: string = 'DATE_ANNIVERSARY';
	@Input() public validatorMessages: ValidatorMessage[];
	public defaultValidatorMessages: ValidatorMessage[];
	public errorMessage: string;

	constructor(
		private errorMessageService: ErrorMessageService,
		private trasnlateService: TranslateService
	) {}

	ngOnInit(): void {
		let fildName: string;
		if (this.placeholder) {
			this.trasnlateService.get(this.placeholder).subscribe({
				next: (res) => (fildName = res),
			});
		}
		this.defaultValidatorMessages = [
			{
				validationType: 'required',
				validationMessage: `${this.trasnlateService.instant(
					this.placeholder ?? 'THIS_FIELD'
				)} ${this.trasnlateService.instant('IS_REQUIRED_GENERAL')}`,
			},
			{
				validationType: 'matDatepickerParse',
				validationMessage:
					this.trasnlateService.instant('WRONG_FORMAT'),
			},

			...(this.validatorMessages ?? []),
		];
		this.setErrorMessage();
	}
	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(
			this.defaultValidatorMessages,
			this.fgName.get(this.controlName)
		);
	}
}
