<app-modal-header-placeholder
	[title]="'EXPIRED_PWD_LINK'"
	[showClose]="false"
	(onClose)="close(false)"
></app-modal-header-placeholder>

<div class="row mt-2">
	<div class="col-12">
		<div class="d-flex justify-content-center">
			{{ "EXPIRED_PWD_LINK_MSG" | translate }}
		</div>
	</div>
</div>

<div class="row mt-2">
	<div class="col-12">
		<div class="d-flex justify-content-center">
			<slr-btn02 (onClick)="close(true)">{{
				"FORGOT_PWD" | translate
			}}</slr-btn02>
		</div>
	</div>
</div>
