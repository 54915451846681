import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { PermissionHelper } from 'src/app/core/helpers/permission-helper.class';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserPermissionService } from 'src/app/modules/authentication/_services/user-permissions.service';
import { DropdownInterface } from 'src/app/shared/components/dropdowns/slr-drp01/dropdown.model';
import { saveProductAccessSettings } from 'src/app/shared/_state/shared.actions';
import { SharedState } from 'src/app/shared/_state/shared.reducer';
import { SettingsService } from '../../_services/settings.service';

@Component({
	selector: 'app-user-access-settings',
	templateUrl: './user-access-settings.component.html',
	styleUrls: [
		'./user-access-settings.component.scss',
		'../settings/settings.component.scss',
	],
})
export class UserAccessSettingsComponent implements OnInit, OnDestroy {
	public permissionHelper = new PermissionHelper();
	public userAccessForm: FormGroup;
	public settings: any;
	public lmsSettings = [];
	public enterpriseSettings = [];
	public qrmSettings = [];
	public policySettings = [];
	public skillsSettings = [];
	public otherSettings = [];
	public userClassData: Array<DropdownInterface> = [
		{
			id: 1,
			name: 'No Access',
		},
		{
			id: 2,
			name: 'System Admin',
		},
		{
			id: 3,
			name: 'Educatino Coordinator',
		},
		{
			id: 4,
			name: 'Department Supervisor',
		},
		{
			id: 5,
			name: 'User',
		},
		{
			id: 6,
			name: 'Restricted User',
		},
		{
			id: 7,
			name: 'Group Restricted User',
		},
	];

	public userClasses = [];

	public userAccessSettingsTabPermissions = [
		this.permissionHelper.settingsModule.USER_ACCESS_LMS,
		this.permissionHelper.settingsModule.USER_ACCESS_ENTERPRISE,
		this.permissionHelper.settingsModule.USER_ACCESS_POLICY_PROFESSIONAL,
		this.permissionHelper.settingsModule.USER_ACCESS_QRM,
	].reduce((a, b, permission) => {
		return {
			...a,
			[b]: this.checkTabSettings(b),
		};
	}, {});

	unsubscriber$ = new Subject();

	constructor(
		private _fb: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private settingsService: SettingsService,
		private notificationService: NotificationService,
		private localDataService: LocalDataService,
		private sharedStore: Store<SharedState>,
		private permissionService: UserPermissionService
	) {}

	ngOnInit(): void {
		this.getUserAccessData();
	}

	ngOnDestroy(): void {
		this.unsubscriber$.next('');
	}

	getUserAccessData() {
		this.activatedRoute.data
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe((data) => {
				// Treating all Settings as LMS till BE send category based User Access Settings.
				this.settings = data['masterDatas'][0].data[0];
				this.userClasses = this.getuserAccess(
					data['masterDatas'][1].data[0]
				);
				this.transformSettings();
			});
	}

	private getuserAccess(classes: any[]): any[] {
		const surgeAdminClass = classes.find((x) => x.classId == 4);
		const surgeAdminClassIndex = classes.findIndex((x) => x.classId == 4);
		if (surgeAdminClass && surgeAdminClassIndex > -1) {
			surgeAdminClass.className = 'No Access';
			classes[surgeAdminClassIndex] = surgeAdminClass;
		}
		return classes;
	}

	transformSettings() {
		this.userClasses.forEach((userClass) => {
			userClass['id'] = userClass.classId;
			userClass['name'] = userClass.className;
		});
		this.sortUserClasses();
		this.settings.forEach((setting) => {
			setting['options'] = this.userClasses;
			setting['displayOrder'] = this.userClasses.find(
				(userClass) => userClass.id === setting.userClassId
			)?.displayOrder;
		});
		this.initForm();
	}

	private sortUserClasses(): void {
		this.userClasses.sort((class1, class2) => {
			const userClass1 = class1.displayOrder,
				userClass2 = class2.displayOrder;
			if (userClass1 < userClass2) {
				return -1;
			}
			if (userClass1 > userClass2) {
				return 1;
			}
			return 0;
		});
	}

	private initForm(): void {
		let formGroup = {};
		this.settings.forEach((setting) => {
			formGroup[setting.comPreName] = new FormControl(
				setting.userClassId
			);
		});
		this.userAccessForm = new FormGroup(formGroup);
	}

	settingChanged(event, setting) {
		setting.userClassId = event.classId;
		setting.displayOrderUserClass = event.displayOrder;
	}

	getEditSettingsPayload() {
		let editSettingsPayload = [];
		this.settings.forEach((setting) => {
			editSettingsPayload.push({
				comPrevId: setting.comPreId,
				displayUserClassId:
					setting?.displayOrderUserClass ?? setting?.displayOrder,
				settingsPrevId: setting.id,
				userClassId: setting.userClassId,
			});
		});

		return editSettingsPayload;
	}

	saveSettings(editHierarchy: boolean) {
		let payload = {
			editHierarchy: editHierarchy,
			editing: true,
			locationId: this.localDataService.getLocationId(),
			settingsUserAccessLocationRequest: this.getEditSettingsPayload(),
			userId: this.localDataService.getUserId(),
		};

		this.settingsService
			.editUserAccessSettings(payload)
			.pipe(takeUntil(this.unsubscriber$))
			.subscribe({
				next: (value) => {
					this.notificationService.success(
						'Updated settings successfully!'
					);
				},
				error: (err) => {
					this.notificationService.error(
						'Unable to update settings!'
					);
				},
			});
	}
	public checkTabSettings(id: number): boolean {
		return this.permissionService.checkUserPrivilege(id);
	}
}
