/* eslint-disable no-unused-vars */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	catchError,
	EMPTY,
	Observable,
	of,
	retry,
	switchMap,
	throwError,
} from 'rxjs';
import { UrlProvider } from './url.provider';

@Injectable({
	providedIn: 'root',
})
export class BaseWebApiService {
	private retryCount: number = 0;
	constructor(
		protected http: HttpClient,
		protected urlProvider: UrlProvider
	) {}

	public get<T>(
		endpoint: string,
		extraParams: any,
		apiFor?: string,
		jsonParse: boolean = false,
		returnFullBody: boolean = true,
		retryCount: number = this.retryCount,
		optionalHeader?: any
	): Observable<T> {
		let params: any = {};

		if (jsonParse) {
			params.format = 'JSON';
		}

		if (extraParams) {
			params = { ...extraParams, ...params };
		}
		return this.http
			.get<T>(this.getUrl(endpoint, apiFor), {
				...this.baseGetOptions(optionalHeader),
				params,
			})
			.pipe(
				switchMap((res: any) => {
					let returnVal = null;
					if (returnFullBody) {
						returnVal = res.body ? res.body : null;
					} else {
						returnVal = res.body ? res.body.result : null;
					}
					//Update the response as NULL
					if (
						res.body &&
						res.body.success !== undefined &&
						res.body.success === false
					) {
						returnVal = null;
					}
					if (jsonParse) {
						return of(JSON.parse(returnVal));
					} else {
						return of(returnVal);
					}
				}),
				retry(retryCount),
				catchError((error: any) => {
					return this.processError(error);
				})
			);
	}

	public put<T>(
		endpoint: string,
		body: any = null,
		extraParams?: any,
		jsonParse: boolean = false,
		urlParams: string[] = [],
		returnFullBody: boolean = true,
		retryCount: number = this.retryCount
	): Observable<T> {
		let params: any = {};
		let endPointUrl = this.getUrl(endpoint);
		if (urlParams && urlParams.length !== 0) {
			urlParams.forEach((param, index) => {
				endPointUrl = endPointUrl.replace(`{${index}}`, param);
			});
		}

		if (jsonParse) {
			params.format = 'JSON';
		}

		if (extraParams) {
			params = { ...extraParams, ...params };
		}
		return this.http
			.put<T>(endPointUrl, body, {
				...this.baseGetOptions(),
				params,
			})
			.pipe(
				switchMap((res: any) => {
					let returnVal = null;
					if (returnFullBody) {
						returnVal = res.body ? res.body : null;
					} else {
						returnVal = res.body ? res.body.result : null;
					}
					//Update the response as NULL
					if (
						res.body &&
						res.body.success !== undefined &&
						res.body.success === false
					) {
						returnVal = null;
					}
					if (jsonParse) {
						return of(JSON.parse(returnVal));
					} else {
						return of(returnVal);
					}
				}),
				retry(retryCount),
				catchError((error: any) => {
					return this.processError(error);
				})
			);
	}

	public post<T>(
		endpoint: string,
		body: any = null,
		extraParams?: any,
		apiFor?: string,
		jsonParse: boolean = false,
		returnFullBody: boolean = true,
		retryCount: number = this.retryCount,
		optionalHeader?: any
	): Observable<T> {
		let params: any = {};

		if (jsonParse) {
			params.format = 'JSON';
		}

		if (extraParams) {
			params = { ...extraParams, ...params };
		}
		return this.http
			.post<T>(this.getUrl(endpoint, apiFor), body, {
				...this.baseGetOptions(optionalHeader),
				params,
			})
			.pipe(
				switchMap((res: any) => {
					let returnVal = null;
					if (returnFullBody) {
						returnVal = res.body ? res.body : null;
					} else {
						returnVal =
							res.body && res.body.result
								? res.body.result
								: null;
						if (res.body && res.body.exception) {
							// eslint-disable-next-line no-undef
							console.warn('res.body.exception', res.body);
						}
					}
					//Update the response as NULL
					if (
						res.body &&
						res.body.success !== undefined &&
						res.body.success === false
					) {
						returnVal = null;
					}
					if (jsonParse) {
						return of(JSON.parse(returnVal));
					} else {
						return of(returnVal);
					}
				}),
				retry(retryCount),
				catchError((error: any) => {
					return this.processError(error);
				})
			);
	}
	public uploadFile<T>(
		endpoint: string,
		body: any = null,
		params: any = {},
		isScorm: boolean = false
	) {
		return this.http
			.post<T>(
				isScorm ? this.getScormUrl(endpoint) : this.getUrl(endpoint),
				body,
				{
					reportProgress: true,
					observe: 'events',
					params,
				}
			)
			.pipe(
				catchError((error: any) => {
					return this.processError(error);
				})
			);
	}

	public patch<T>(
		endpoint: string,
		body: any = null,
		jsonParse: boolean = false,
		urlParams?: string[],
		extraParams?: any,
		optionalHeader?: any,
		useOriginal: boolean = false
	): Observable<T> {
		let params: any = {};
		let endPointUrl = useOriginal ? endpoint : this.getUrl(endpoint);
		if (urlParams && urlParams.length !== 0) {
			urlParams.forEach((param, index) => {
				endPointUrl = endPointUrl.replace(`{${index}}`, param);
			});
		}

		if (jsonParse) {
			params.format = 'JSON';
		}

		if (extraParams) {
			params = { ...extraParams, ...params };
		}
		return this.http
			.patch(endPointUrl, body, {
				...this.baseGetOptions(optionalHeader),
				params,
			})
			.pipe(
				switchMap((res: any) => {
					return of(res);
				}),
				catchError((error: any) => {
					return this.processError(error);
				})
			);
	}

	public delete<T>(
		endpoint: string,
		body: any = null,
		jsonParse: boolean = false,
		urlParams?: string[],
		extraParams?: any,
		isScorm: boolean = false
	): Observable<T> {
		let params: any = {};
		let endPointUrl = isScorm
			? this.getScormUrl(endpoint)
			: this.getUrl(endpoint);
		if (urlParams && urlParams.length !== 0) {
			urlParams.forEach((param, index) => {
				endPointUrl = endPointUrl.replace(`{${index}}`, param);
			});
		}

		if (jsonParse) {
			params.format = 'JSON';
		}

		if (extraParams) {
			params = { ...extraParams, ...params };
		}
		return this.http
			.delete<T>(endPointUrl, {
				...this.baseGetOptions(),
				params,
				body,
			})
			.pipe(
				switchMap((res: any) => {
					return of(res);
				}),
				catchError((error: any) => {
					return this.processError(error);
				})
			);
	}
	public head<T>(
		endpoint: string,
		body: any = null,
		apiFor?: string,
		jsonParse: boolean = false,
		urlParams?: string[],
		extraParams?: any,
		optionalHeader?: any,
		useOriginal: boolean = false
	): Observable<T> {
		let params: any = {};
		let endPointUrl = useOriginal
			? endpoint
			: this.getUrl(endpoint, apiFor);
		if (urlParams && urlParams.length !== 0) {
			urlParams.forEach((param, index) => {
				endPointUrl = endPointUrl.replace(`{${index}}`, param);
			});
		}

		if (jsonParse) {
			params.format = 'JSON';
		}

		if (extraParams) {
			params = { ...extraParams, ...params };
		}
		return this.http
			.head<T>(endPointUrl, {
				...this.baseGetOptions(optionalHeader),
				params,
				body,
			})
			.pipe(
				switchMap((res: any) => {
					return of(res);
				}),
				catchError((error: any) => {
					return this.processError(error);
				})
			);
	}

	protected baseGetOptions(optionalHeader?: any): any {
		return {
			headers: this.getAuthHeader(optionalHeader),
			observe: 'response',
		};
	}

	protected getAuthHeader(optionalHeader?: any): HttpHeaders {
		return new HttpHeaders({
			...optionalHeader,
		});
	}

	protected getUrl(endpoint: string, apiFor?: string) {
		return this.urlProvider.getUrl(endpoint, apiFor);
	}
	protected getScormUrl(endpoint: string) {
		return this.urlProvider.getScormUrl(endpoint);
	}

	protected processError(
		error: any,
		ignoreErrorCode?: number[],
		throwOnErrorCode?: number[]
	): Observable<any> {
		if (
			throwOnErrorCode &&
			throwOnErrorCode.length !== 0 &&
			error.status &&
			throwOnErrorCode.includes(error.status)
		) {
			return throwError(() => error);
		}
		if (error.status && error.status === 401) {
			return of(EMPTY);
		}
		if (
			ignoreErrorCode &&
			ignoreErrorCode.length !== 0 &&
			error.status &&
			ignoreErrorCode.includes(error.status)
		) {
			return of([]);
		}
		if (
			error.status &&
			(error.status === 500 ||
				error.status === 400 ||
				error.status === 204)
		) {
			return of([]);
		}
		return throwError(() => error);
	}
}
