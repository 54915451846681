import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { BroadcastService } from 'src/app/core/services/broadcast.service';
@Component({
	selector: 'app-private-layout',
	templateUrl: './private-layout.component.html',
	styleUrls: ['./private-layout.component.scss'],
})
export class PrivateLayoutComponent implements OnDestroy {
	public isReportsScreen = false;
	private unsubscribe$ = new Subject();
	public miniSidebar = true;
	public hiddenSidebar = false;
	constructor(
		private broadcastService: BroadcastService,
		router: Router
	) {
		router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeUntil(this.unsubscribe$)
			)
			.subscribe(
				(route: NavigationEnd) =>
					(this.isReportsScreen = route.url
						.toString()
						.includes('reports'))
			);

		this.broadcastService.screenSize.subscribe({
			next: (res) => {
				this.hiddenSidebar = res == 'small';
			},
		});
	}
	ngOnDestroy(): void {
		this.unsubscribe$.next(true);
	}
	public toggleContentArea(): void {
		this.miniSidebar = !this.miniSidebar;
	}
}
