import { Component, ViewChild,Input } from '@angular/core';
import Chart from 'chart.js/auto'

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent {
  canvas: any;
  ctx: any;
  @ViewChild('pieCanvas') pieCanvas!: { nativeElement: any };

  @Input() public Monthdata: any;
  @Input() public dataarray: any;
  pieChart: any;
  public myChart: Chart

  ngAfterViewInit(): void {
    this.pieChartBrowser();
  }
  ngOnChanges(changes: any) {
    this.pieChartBrowser();

  }

  pieChartBrowser(): void {
    if (this.pieChart) this.pieChart.destroy();
    this.canvas = this.pieCanvas.nativeElement;
   this.ctx = this.canvas.getContext('2d');
  
this.pieChart = new Chart(this.ctx, {
      type: 'pie',
      data: {
       labels: this.Monthdata,
        datasets: [
          {
            backgroundColor: [
              '#2ecc71',
              '#3498db',
              '#95a5a6',
              '#9b59b6',
              '#f1c40f',
              '#e74c3c',
              '#3498db',
              '#95a5a6',
              '#9b59b6',
              '#f1c40f',
              '#e74c3c',
            ],
            data: this.dataarray[0].values,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
						display: true,
					},
          
         
        }
      }
     
      
    });

  }
}



