import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, Optional, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, never, Observable, Subject, take, takeUntil } from 'rxjs';
import { GenericHelper } from 'src/app/core/helpers/generic-helper.class';
import {
	OptionsInterface,
	QuestionsInterface,
} from 'src/app/core/models/take-test.interface';
import { SlrUrlQueryParams } from 'src/app/core/models/url-params.enum';
import { FileUploadService } from 'src/app/core/services/file-upload.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { DropdownInterface } from 'src/app/shared/components/dropdowns/slr-drp01/dropdown.model';
import { AppConstants } from 'src/app/shared/constants/app-constants';
import { TakeTestService } from '../_services/take-test.service';
import { selectAnswer as selectAnswer } from '../_state/my-course.action';
import { MyCourseState } from '../_state/my-course.reducer';
import { MatRadioChange } from '@angular/material/radio';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-test-question',
	templateUrl: './test-question.component.html',
	styleUrls: ['./test-question.component.scss'],
})
export class TestQuestionComponent implements OnInit, OnDestroy {
	@Input() public question: QuestionsInterface;
	@Input() public index: number;
	@Optional() @Input() public keepDisabled: boolean;
	public questionImageS3String: Observable<string>;
	public showTestAnswers: boolean;
	public showSurveyAnswers: boolean;
	private unsubscriber = new Subject<void>();
	public options: Array<DropdownInterface>;
	public selection = new SelectionModel<number>(true);
	public addCommentOpen: boolean = false;
	public addCommentControl = new FormControl();

	constructor(
		private _route: ActivatedRoute,
		private translateService: TranslateService,
		private takeTestService: TakeTestService,
		private takeTestStore: Store<MyCourseState>,
		private notificationService: NotificationService,
		private fileUploadService: FileUploadService
	) {
		this._route.queryParams
			.pipe(takeUntil(this.unsubscriber))
			.subscribe((params) => {
				this.showTestAnswers =
					params[SlrUrlQueryParams.SHOW_TEST_ANSWERS];
			});
		this._route.url.pipe(takeUntil(this.unsubscriber)).subscribe((url) => {
			const stringUrl = url.toString();
			console.log(stringUrl);
			this.showSurveyAnswers = stringUrl.includes('preview-survey');
			console.log(this.showSurveyAnswers);
		});
	}

	ngOnInit(): void {
		this.options = this.question.options.map((x) => {
			return {
				id: x.valueId,
				name: x.option,
				data:
					x.optionImageUrl !== null
						? {
								image: {
									src: this.fileUploadService
										.getPresignedUrl(x.optionImageUrl)
										.pipe(
											take(1),
											map((res) => res.s3PresingedURL)
										),
								},
								rationale: x.rationale,
								disabled: this.keepDisabled,
						  }
						: {
								rationale: x.rationale,
								disabled: this.keepDisabled,
						  },
			};
		});
		if (this.question.questionImageUrl) {
			this.questionImageS3String = this.fileUploadService
				.getPresignedUrl(this.question.questionImageUrl)
				.pipe(
					take(1),
					map((res) => res.s3PresingedURL)
				);
		}
	}

	public updateMCQAnswer(option: MatRadioChange): void {
		this.takeTestStore.dispatch(
			selectAnswer({
				qid: this.question.questionId,
				selectedOptions: [option.value],
			})
		);
	}

	public updateMultipleAnswer(
		option: DropdownInterface,
		event: boolean
	): void {
		event
			? this.selection.select(option.id)
			: this.selection.deselect(option.id);
		this.takeTestStore.dispatch(
			selectAnswer({
				qid: this.question.questionId,
				selectedOptions: this.selection.selected,
			})
		);
	}

	public updateParagraphAnswer(value: string): void {
		this.takeTestStore.dispatch(
			selectAnswer({
				qid: this.question.questionId,
				selectedOptions: value,
			})
		);
	}

	public toggleAddComment(): void {
		this.addCommentOpen = !this.addCommentOpen;
	}

	public updateComment(event: string): void {
		this.addCommentControl.setValue(event);
	}

	public saveQuestionComment(): void {
		const payload: {
			comment: string;
			questionId: number;
			testId: number;
			userId: number;
		} = {
			comment: this.addCommentControl.value,
			questionId: this.question.questionId,
			testId: +GenericHelper.decode(this._route.snapshot.params['id']),
			userId: null,
		};
		this.takeTestService
			.saveQuestionComment(payload)
			.pipe(take(1))
			.subscribe({
				next: (res) => {
					this.addCommentOpen = false;
					this.notificationService.success(
						this.translateService.instant(
							'COMMENT_ADDED_SUCCESSFULLY'
						)
					);
				},
			});
	}

	ngOnDestroy(): void {
		this.unsubscriber.next();
		this.unsubscriber.unsubscribe();
	}
}
