import { Component, OnInit } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
	AbstractControl,
	ValidatorFn,
} from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import moment from 'moment';

import { filter, Subject, take, takeUntil } from 'rxjs';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { DashboardService } from 'src/app/modules/dashboard/_services/dashboard.service';
import {
	ChatUserInterface,
	ConversationInterface,
	ConversationModel,
	MessageInterface,
	TemporaryMessage,
} from 'src/app/modules/broadcast-and-messaging/_models/conversation.interface';
import { UserClassInterface } from 'src/app/modules/manage-users/_models/user-class.model';
import { ManageUserService } from 'src/app/modules/manage-users/_services/manage-user.service';
import { Group, LocationList, User } from '../../models/locations';
import { ApiUrlService } from 'src/app/core/services/api-url.service';
import { BroadcastdeleteComponent } from 'src/app/shared/components/broadcastdelete/broadcastdelete.component';

import {
	getCurrentChatUser,
	getUserLocation,
	SharedState,
	getSelectedLocation,
} from '../../_state/shared.reducer';
import { DropdownInterface } from '../dropdowns/slr-drp01/dropdown.model';
import { BroadcastService } from './broadcast.service';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { BasicBroadcast } from '../../models/broadcast.interface';
import { BroadcastService as CoreBroadcastService } from 'src/app/core/services/broadcast.service';

@Component({
	selector: 'app-broadcast-popup',
	templateUrl: './broadcast-popup.component.html',
	styleUrls: ['./broadcast-popup.component.scss'],
})
export class BroadcastPopupComponent implements OnInit {
	private unSubscribe = new Subject();
	public Send_Broadcast = true;
	public listBroadcast = false;
	rendomnumbers: number;

	public locations: LocationList;
	public locationData: Array<DropdownInterface>;
	public userData: Array<DropdownInterface>;
	public groups: Array<Group>;
	public groupData: Array<DropdownInterface>;
	public userRoles: UserClassInterface[];
	public userRoleData: any;
	public userRolesDatas: any = [];
	public isSurgeUser: any;
	postdata = true;
	updatedata = false;
	clientcode: any;
	productArr = [];
	selecteduser: any;
	clientobj: any;
	vendorObj: { id: ''; name: '' };
	classobj: { id: ''; name: '' };
	productobj: { id: ''; name: ''; data: null };
	announcementId: any;
	public productdatabroadcast: any = [
		{
			id: 'LMS',
			name: 'LMS',
			data: null,
		},
		{
			id: 'QRM',
			name: 'QRM',
			data: null,
		},
		{
			id: 'Policy',
			name: 'Policy',
			data: null,
		},
	];
	Hostdata: any;
	public sendMessageAsData: Array<DropdownInterface> = [
		{
			id: 1,
			name: 'ONLY_MESSAGE_BOARD',
			data: {
				selected: false,
			},
		},
		{
			id: 2,
			name: 'MESSAGE_BOARD_AND_EMAIL',
			data: {
				selected: true,
			},
		},
	];

	public isMobile: boolean = false;
	public newMessageForm: FormGroup;

	public broadcastform: FormGroup;
	public broadcastsearchForm: FormGroup;
	public validEmailCount: number = undefined;
	public newConversation: ConversationInterface;
	public isChildLocation: boolean = false;
	public today = new Date();
	private currentUser: ChatUserInterface;
	private unSubscriber$ = new Subject<boolean>();
	Typedata: { id: number; name: string; data: any }[];
	Loccode: { id: number; name: string; data: any }[];
	vendorData: any;
	Listflag = false;
	vendorArray = [];
	vendorArr: any;
	privileges: any;
	userClass: { id: number; name: string }[];
	brodData: any;
	databroadcast: any;
	private isValid(): boolean {
		return this.broadcastform.valid;
	}
	public tabSet = [
		{
			name: this.translateService.instant('SEND_BROADCAST'),
			id: 1,
		},
		{
			name: this.translateService.instant('LIST'),
			id: 2,
		},
	];

	Broadcastlist: any;

	constructor(
		private sharedStore: Store<SharedState>,
		private matDialog: MatDialog,
		private dialogRef: MatDialogRef<BroadcastPopupComponent>,
		private router: Router,
		private notificationService: NotificationService,
		private localDataService: LocalDataService,
		private _fb: FormBuilder,
		public ApiService: ApiUrlService,
		private manageUserService: ManageUserService,
		private dashboardService: DashboardService,
		private deletedialogRef: MatDialogRef<BroadcastdeleteComponent>,
		private broadcastService: BroadcastService,
		private coreBroadcastService: CoreBroadcastService,
		private localData: LocalDataService,
		private translateService: TranslateService
	) {
		if (this.localData.getSiteCode() === 'surge') {
			this.getadminform();
			this.isSurgeUser = true;
		} else {
			this.isSurgeUser = false;
			this.getothersform();
		}
		this.sharedStore
			.pipe(
				select(getSelectedLocation),
				filter((value) => !!value && !!value.location)
			)
			.subscribe({
				next: (res) => {
					this.clientcode = res?.location?.sitecode;
				},
			});
		this.sharedStore.pipe(select(getCurrentChatUser)).subscribe({
			next: (res) => {
				this.currentUser = res;
				this.newConversation = new ConversationModel(
					new TemporaryMessage(this.currentUser)
				);
			},
		});

		this.broadcastsearchForm = this._fb.group({
			fromDate: new FormControl('', [Validators.required]),
			toDate: new FormControl('', [Validators.required]),
		});
		this.sharedStore
			.pipe(
				select(getUserLocation),
				takeUntil(this.unSubscriber$),
				filter((value) => !!value || !!value.userList)
			)
			.subscribe({
				next: (res) => {
					this.locations = res;
					this.groups =
						res.grandparentLocationList.groupList ??
						res.grandparentLocationList.parentLocationList[0]
							.groupList ??
						res.grandparentLocationList.parentLocationList[0]
							.childLocationList[0].groupList;

					this.initData();
				},
			});
	}

	private initTabSet() {
		this.translateService.get(['SEND_BROADCAST', 'LIST']).subscribe({
			next: ({ SEND_BROADCAST, LIST }) => {
				this.tabSet[0].name = SEND_BROADCAST;
				this.tabSet[1].name = LIST;
			},
		});
	}

	get message(): AbstractControl | null {
		return this.broadcastform.get('message');
	}

	private initData(): void {
		this.broadcastService
			.getVendorCodes()
			.pipe(takeUntil(this.unSubscribe))
			.subscribe({
				next: (response) => {
					this.vendorData = response;
					for (let i = 0; i < this.vendorData?.length; i++) {
						this.clientobj = this.vendorData[i];
						if (this.clientobj.clientName != null) {
							this.clientobj.id = this.clientobj.codeLookupId;
							this.clientobj.name = this.clientobj.clientName;
							this.vendorArray.push(this.clientobj);
						}
					}

					this.vendorArr = this.vendorArray.sort((a, b) =>
						a.clientName.localeCompare(b.clientName)
					);
				},
				error: (err) => {
					this.notificationService.error(
						'API Error: Unable to fetch Client code'
					);
				},
			});
		this.userClass = [
			{
				id: 1,
				name: 'User or higher',
			},
			{
				id: 2,
				name: 'Education coordinator',
			},
		];

		this.manageUserService
			.getUserClasses()
			.pipe(take(1))
			.subscribe({
				next: (res) => {
					this.userRoles = res;
					this.userRoleData = this.userRoles.map((x) => {
						return {
							id: x.userClassId,
							name: x.userClassName,
							data: null,
						};
					});
					for (let i = 0; i < this.userRoleData.length; i++) {
						let item = this.userRoleData[i];
						if (item.name == 'Surge Administrator')
							this.userRoleData.splice(i, 1);
					}
					this.userRolesDatas = this.userRoleData;
				},
			});
	}

	ngOnInit(): void {
		if (this.broadcastService.subsVar == undefined) {
			this.broadcastService.subsVar =
				this.broadcastService.invokesearchfunction.subscribe(
					(name: string) => {
						this.Listflag = false;
					}
				);
		}
		this.getSupervisors();
	}
	private getSupervisors(): void {
		this.broadcastService
			.getSupervisorUsers()
			.pipe(takeUntil(this.unSubscriber$))
			.subscribe({
				next: (res) => {
					this.userData = res.data
						.map((user) => ({
							name: `${user.firstname} ${user.lastname ?? ''}`,
							id: user.user_id_user,
						}))
						.sort((a, b) =>
							a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
						);
				},
			});
	}
	public onSendEmail(event: DropdownInterface): void {
		this.newMessageForm.get('sendEmails').setValue(event.id === 2);
	}

	public createNewConversation(event: MessageInterface): void {
		if (!this.isValid()) {
			this.broadcastform.markAllAsTouched();
			return;
		}
		let postpayload = this.broadcast;
		if (this.isSurgeUser) {
			postpayload.vendorCode = this.broadcastform.value.byvendor;
			for (let i = 0; i < this.broadcastform.value.product.length; i++) {
				let item;
				item = this.broadcastform.value.product[i];
				postpayload.product.push(item.name);
			}
		}
		this.broadcastService
			.sendbroadcast(postpayload)
			.pipe(take(1))
			.subscribe({
				next: (res) => {
					this.notificationService.success(res.message);
					this.close();
					this.refreshData();
					this.getbroadcastdata();
				},
				error: (err) => {
					this.notificationService.error('API Error: API error');
				},
			});
	}
	getbroadcastdata() {
		this.dashboardService
			.getbroadcastdata()
			.pipe(takeUntil(this.unSubscribe))
			.subscribe({
				next: (response) => {
					this.brodData = response.data;
				},
				error: (err) => {
					this.notificationService.error(
						'API Error: Unable to fetch broadcast data'
					);
				},
			});
	}
	private get broadcast(): BasicBroadcast {
		const { value } = this.broadcastform;
		return {
			name: value.name,
			link: value.link,
			hosted_by: value.host,
			description: value.message,
			date: moment(value.date).format('YYYY-MM-DD'),
			active: 'true',
			announcementId: 0,
			clientCode: this.clientcode,
			targetRoles: value.userclass?.map(
				(userClass: { id: number }) => userClass.id
			),
			multidate: 0,
			multidateDates: '',
			createdBy: this.localDataService.getUserId(),
			type: '',
			product: [],
			targetRole: 0,
			vendor: '',
			role: '',
			vendorCode: 0,
		};
	}
	public close(): void {
		this.dialogRef.close();
	}

	ngOnDestroy(): void {
		this.unSubscriber$.next(true);
	}
	TabClick(list: any) {
		if (list == this.tabSet[0].id) {
			this.Send_Broadcast = true;
			this.broadcastform.reset();
			this.postdata = true;
			this.listBroadcast = false;
			this.updatedata = false;
		} else if (list == this.tabSet[1].id) {
			this.Send_Broadcast = false;
			this.listBroadcast = true;
		}
	}
	editbroadcast(obj) {
		this.productArr = [];
		this.Send_Broadcast = true;
		this.updatedata = true;
		this.postdata = false;
		this.postdata = false;
		this.listBroadcast = false;
		this.announcementId = obj.announcementId;
		let item = obj.product.split(',');
		for (let i = 0; i < item.length; i++) {
			this.productobj = { id: '', name: '', data: null };
			let items = item[i];
			this.productobj.id = items;
			this.productobj.name = items;
			this.productArr.push(this.productobj);
		}
		const userClasses = this.userRolesDatas.filter((role: { id: number }) =>
			obj.targetRoles.includes(role.id)
		);
		const [year, month, day] = obj.date.split('-');
		const date = new Date(+year, +month - 1, +day);
		if (this.isSurgeUser) {
			this.broadcastform.setValue({
				name: obj.name,
				host: obj.hosted_by,
				link: obj.link,
				date,
				product: this.productArr,
				message: obj.description,
				byvendor: obj.vendorCode,
				userclass: userClasses,
			});
		} else {
			this.broadcastform.setValue({
				name: obj.name,
				host: obj.hosted_by,
				link: obj.link,
				date,
				message: obj.description,
				userclass: userClasses,
			});
		}
	}
	updateForm() {
		if (!this.isValid()) {
			this.broadcastform.markAllAsTouched();
			return;
		}
		let updatePayload = this.broadcast;
		if (this.isSurgeUser) {
			updatePayload.vendorCode = this.broadcastform.value.byvendor;
			for (let i = 0; i < this.broadcastform.value.product.length; i++) {
				let item;
				item = this.broadcastform.value.product[i];
				updatePayload.product.push(item.name);
			}
		}
		updatePayload.announcementId = this.announcementId;
		this.broadcastService
			.sendbroadcast(updatePayload)
			.pipe(take(1))
			.subscribe({
				next: (res) => {
					this.notificationService.success(res.message);
					this.close();
					this.refreshData();
					this.getbroadcastdata();
				},
				error: (err) => {
					this.notificationService.error('API Error: API error');
				},
			});
	}
	gotolist() {
		this.listBroadcast = true;
		this.Send_Broadcast = false;
	}
	deletepopup(obj) {
		this.broadcastService.deleteobj = obj;
		this.deletedialogRef = this.matDialog.open(BroadcastdeleteComponent, {
			width:
				this.coreBroadcastService.screenSize.value === 'small'
					? '80%'
					: '40%',
		});
		this.deletedialogRef.afterClosed().subscribe((res) => {
			res && this.broadcastService.refreshBroadcasts$.next(true);
		});
	}

	linkFormatValidator(): ValidatorFn {
		return (control: FormControl): { [key: string]: any } | null => {
			const link = control.value;
			if (!link || /^(https?:\/\/)/.test(link)) {
				return null;
			} else {
				return { invalidLinkFormat: true };
			}
		};
	}
	getadminform() {
		this.broadcastform = this._fb.group({
			name: new FormControl('', [Validators.required]),
			link: new FormControl('', [this.linkFormatValidator()]),
			host: new FormControl(null, [Validators.required]),
			date: new FormControl('', [Validators.required]),
			message: new FormControl('', [Validators.required]),
			product: new FormControl(null, [Validators.required]),
			byvendor: new FormControl(null, [Validators.required]),
			userclass: new FormControl(null, [Validators.required]),
		});
	}
	getothersform() {
		this.broadcastform = this._fb.group({
			name: new FormControl('', [Validators.required]),
			link: new FormControl('', [this.linkFormatValidator()]),
			host: new FormControl(null, [Validators.required]),
			date: new FormControl('', [Validators.required]),
			message: new FormControl('', [Validators.required]),
			userclass: new FormControl(null, [Validators.required]),
		});
	}
	refreshData() {
		this.rendomnumber();
		this.router.navigate(['/dashboard', this.rendomnumbers]);
	}
	rendomnumber() {
		this.rendomnumbers = 0;
		this.rendomnumbers = Math.floor(Math.random() * 100);
	}
}
