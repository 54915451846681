<div class="table-responsive">
	<table class="table lms">
		<ng-container>
			<thead>
				<tr>
					<!-- For CheckBox -->
					<th></th>
					<th>
						{{ "SKILLS_DESCRIPTION" | translate
						}}<span
							(click)="sortData('skillName')"
							(keyup)="sortData('skillName')"
							class="material-icons-outlined sort-icons cp"
							>unfold_more</span
						>
					</th>
					<th>{{ "COMPETENCY" | translate }}</th>
					<th>
						{{ "OBTAINED_DATE" | translate }}
					</th>
					<th>
						{{ "RENEWAL_DATE" | translate }}
					</th>
					<th>
						{{ "ALERT_DATE" | translate }}
					</th>
					<th>
						{{ "LAST_UPDATED_DATE" | translate }}
					</th>
				</tr>
			</thead>
			<tbody>
				<ng-container *ngFor="let skill of filteredSkills">
					<tr
						skillRowLegend
						skill-entry
						[skillId]="skill.skillId"
						[name]="skill.skillName"
						[description]="skill.description"
						[renewalType]="skill.renewalType"
						[userId]="userId"
						[isManagedUsers]="isManagedUsers"
						[datesOverridesAllowed]="skill.datesOverridesAllowed"
						[useComp]="skill.useCompetency"
						[hasComment]="skill.hasComments"
						[hasCertificate]="skill.hasCertificates"
						[defaultRenewalFrequency]="
							skill.defaultRenewalFrequency
						"
						[newRenewalFrequencyInYears]="
							skill.newRenewalFrequencyInYears
						"
						[defaultAlertsThresholdInDays]="
							skill.defaultAlertsThresholdInDays
						"
						[hasCourseAttached]="skill.courseId !== 0"
						(onSelectionChange)="toggleSelection($event)"
					></tr>
				</ng-container>
			</tbody>
		</ng-container>
	</table>
</div>
