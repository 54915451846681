import { Component,Input } from '@angular/core';

@Component({
  selector: 'app-broadcast-message',
  templateUrl: './broadcast-message.component.html',
  styleUrls: ['./broadcast-message.component.scss']
})
export class BroadcastMessageComponent {

  @Input() content: any;

  gotoregister(obj:any){
    window.open(obj.link);
  }

}
