import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { MatTree, MatTreeNestedDataSource } from '@angular/material/tree';
import { select, Store } from '@ngrx/store';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { TreeModel } from '../../models/locations';
import { getLocationTreeFromServer } from '../../_state/shared.actions';
import {
	getLocationTree,
	SharedState,
} from '../../_state/shared.reducer';

@Component({
	selector: 'app-location-tree',
	templateUrl: './location-tree.component.html',
	styleUrls: ['./location-tree.component.scss'],
})
export class LocationTreeComponent implements OnInit, OnChanges {
	@ViewChild('tree') tree: MatTree<any>;

	@Input() public title: string;
	@Input() public isFromSurvey: boolean;
	@Input() public disabled: boolean = false;
	@Output() public childLocation = new EventEmitter();
	@Output() public onSelectionChange = new EventEmitter<
		SelectionChange<number>
	>();
	public selection: SelectionModel<number> = new SelectionModel(true, []);
	public treeDataCount: number = 1;
	public treeData = [];
	public filteredTreeData = [];
	public data = [];
	public seeLess = true;
	public showSeeLessButton = true;

	public subscription;
	public count = 0;
	public nestedTreeControl: NestedTreeControl<TreeModel>;
	public nestedDataSource: MatTreeNestedDataSource<TreeModel>;
	public filteredData: MatTreeNestedDataSource<TreeModel> =
		new MatTreeNestedDataSource();
	public inputValue;

	private unsubscribe$ = new Subject<void>();

	constructor(
		private sharedStore: Store<SharedState>
	) {
		this.nestedTreeControl = new NestedTreeControl<TreeModel>(
			this._getChildren
		);
		this.filteredData = this.nestedDataSource =
			new MatTreeNestedDataSource();

		this.selection.changed.pipe(debounceTime(500)).subscribe((x) => {
			this.onSelectionChange.emit(x);
		});
	}

	ngOnInit(): void {
		this.updateData();
		this.sharedStore
			.pipe(select(getLocationTree), takeUntil(this.unsubscribe$))
			.subscribe({
				next: (res) => {
					if (!res) {
						this.sharedStore.dispatch(getLocationTreeFromServer());
						return;
					}

					this.makeTree(res);
					this.getTreeDataCount(res);
				},
			});
	}

	ngOnChanges(): void {
		if (this.isFromSurvey === undefined) this.isFromSurvey = false;
		else this.isFromSurvey = true;
	}

	public makeTree(res) {
		this.treeData = [
			res?.grandParent
				? {
						locationId: res?.grandParent?.grandParentLocationId,
						locationName: res?.grandParent?.grandParentLocationName,
						children: this._getParent(res?.grandParent?.parents),
				  }
				: res?.parent
				? {
						locationId: res?.parent?.parentLocationId,
						locationName: res?.parent?.parentLocationName,
						children: this._getChild(res?.parent),
				  }
				: {
						locationId: res?.child.locationId,
						locationName: res?.child?.locationName,
						children: [],
				  },
		];

		const data = this.treeData;
		data.forEach((d) => {
			d['checked'] = false;
			d['expanded'] = true;

			return d;
		});

		if (this.treeData.length == 0) {
			this.showSeeLessButton = false;
			this.seeLess = false;
			this.updateData();
			return;
		} else {
			this.seeLess = true;
			this.updateData();
		}
	}

	public getTreeDataCount(res) {
		if (res?.grandParent) {
			res?.grandParent?.parents?.forEach((child) => {
				this.treeDataCount = this.treeDataCount + child.childs.length;
			});
			this.treeDataCount =
				this.treeDataCount + res?.grandParent?.parents.length;
		} else if (res?.parent) {
			this.treeDataCount =
				this.treeDataCount + res?.parent?.childs.length;
		} else {
			if (this.isFromSurvey) {
				this.treeDataCount = 0;
				this.childLocation.emit(res.child.locationId);
			}
		}
	}

	/** Checks if datasource for material tree has any child groups */
	hasNestedChild = (_: number, nodeData: TreeModel) =>
		nodeData.children.length > 0;

	/** Returns child groups from security group */
	private _getChildren = (node: TreeModel) => node.children;

	clickedActive(element) {
		this.selection.toggle(element.locationId);
		element.checked = !element.checked;
	}

	/** Loops recursively through data finding the amount of checked children */
	getCheckedAmount(data) {
		this.count = 0; // resetting count
		this.loopData(data.children);
		return this.count;
	}

	/** Used by getCheckedAmount() */
	loopData(data) {
		data.forEach((d) => {
			if (d.checked) {
				this.count += 1;
			}
			if (d.children && d.children.length > 0) {
				this.loopData(d.children);
			}
		});
	}

	changeState(data) {
		data.expanded = !data.expanded;
	}

	_getParent(parents): any {
		let children = [];
		parents?.forEach((parent) => {
			children.push({
				locationId: parent?.parentLocationId,
				locationName: parent?.parentLocationName,
				children: this._getChild(parent),
			});
		});
		return children;
	}

	_getChild(parent): any {
		let children = [];
		parent?.childs?.forEach((child) => {
			children.push({
				locationId: child?.locationId,
				locationName: child?.locationName,
				children: [],
			});
		});
		return children;
	}

	public onToggle(checked: boolean): void {
		if (checked) {
			this.treeData.forEach((x) => {
				x.checked = true;
				this.selection.select(x.locationId);
				x.children?.forEach((parent) => {
					parent.checked = true;
					this.selection.select(parent.locationId);
					parent.children?.forEach((child) => {
						child.checked = true;
						this.selection.select(child.locationId);
					});
				});
			});
			const data = this.treeData;

			data.forEach((d) => {
				d['checked'] = true;
				d['expanded'] = true;

				return d;
			});
		} else {
			this.selection.clear();
			this.treeData.forEach((x) => {
				x.checked = false;
				x.children?.forEach((parent) => {
					parent.checked = false;
					parent.children?.forEach((child) => {
						child.checked = false;
					});
				});
			});
			const data = this.treeData;
			data.forEach((d) => {
				d['checked'] = false;
				d['expanded'] = true;

				return d;
			});
		}
	}

	public updateData(): void {
		if (this.treeData[0]?.children.length > 3) {
			this.filteredTreeData = [
				{
					locationId: this.treeData[0]?.locationId,
					locationName: this.treeData[0]?.locationName,
					children: this.treeData[0].children.slice(0, 3),
				},
			];
			const data = this.filteredTreeData;
			data.forEach((d) => {
				d['checked'] = false;
				d['expanded'] = true;

				return d;
			});
		} else this.filteredTreeData = this.treeData;
		this.filteredData.data = this.seeLess
			? this.filteredTreeData
			: this.treeData;
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.unsubscribe();
	}
}
