<div *ngIf="treeDataCount" class="row g-0 align-items-center mt-2">
	<div class="col-3">
		<div class="title">{{ title }}:</div>
	</div>

	<div class="col-3 d-flex justify-content-end">
		<slr-toggle
			[checked]="this.selection.selected?.length == treeDataCount"
			[disabled]="disabled"
			[label]="'Select All'"
			(toggle)="onToggle($event)"
		></slr-toggle>
	</div>
</div>
<mat-tree
	*ngIf="treeDataCount"
	#tree
	[dataSource]="nestedDataSource"
	[treeControl]="nestedTreeControl"
	class="location-tree"
>
	<mat-nested-tree-node *matTreeNodeDef="let data; when: hasNestedChild">
		<ul>
		<li>
			<div class="mat-tree-node">
				<button
					mat-icon-button
					[attr.aria-label]="'toggle ' + data.locationId"
					(click)="changeState(data)"
				>
					<mat-icon class="mat-icon-rtl-mirror">
						{{ data.expanded ? "expand_more" : "chevron_right" }}
					</mat-icon>
				</button>
				<div>
					<span>
						<mat-checkbox
							[checked]="selection.isSelected(data.locationId)"
							(change)="clickedActive(data)"
						>
						</mat-checkbox>
					</span>
				</div>
				<span>{{ data.locationName }}</span>
			</div>
			<ul [class.location-tree-invisible]="!data.expanded">
				<ng-container matTreeNodeOutlet></ng-container>
			</ul>
		</li>
	</ul>
	</mat-nested-tree-node>
</mat-tree>
<div *ngIf="showSeeLessButton && treeDataCount" class="col-9">
	<a (click)="seeLess = !seeLess; updateData()" href="javascript:void(0)"
		>See {{ seeLess ? "More" : "Less" }}</a
	>
</div>
