import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, map, Observable, withLatestFrom } from 'rxjs';
import { UrlNames } from 'src/app/core/services/urlProfiler';
import { BaseWebApiService } from 'src/app/core/services/_base-web-api.service';
import {
	getUserLocation,
	getUserPrivileges,
	SharedState,
} from 'src/app/shared/_state/shared.reducer';
import {
	BroadcastMessageInterface,
	SendMessageApi,
} from '../_models/conversation.interface';

@Injectable({
	providedIn: 'root',
})
export class ChatService {
	public userGroups: any;
	private userId: number;
	constructor(
		private sharedStore: Store<SharedState>,
		private baseApi: BaseWebApiService
	) {
		this.sharedStore
			.pipe(
				withLatestFrom(getUserLocation),
				filter((value) => !!value)
			)
			.subscribe({
				next: (res) => (this.userGroups = res),
			});
		this.sharedStore
			.pipe(
				select(getUserPrivileges),
				filter((value) => !!value)
			)
			.subscribe({
				next: (res) => {
					this.userId = res.userId;
				},
			});
	}

	public getAllMessages(): Observable<BroadcastMessageInterface> {
		return this.baseApi.get(`${UrlNames.getAllMessages}`, null);
	}
	public getMessageByUserId(): Observable<Array<BroadcastMessageInterface>> {
		return this.baseApi
			.get<any>(`${UrlNames.getAllMessages}/user/${this.userId}`, null)
			.pipe(map((res) => res.data));
	}

	public sendMessage(payload: SendMessageApi): Observable<any> {
		payload = {
			...payload,
			userId: this.userId,
		};
		return this.baseApi.post(`${UrlNames.sendMessagesEncrypted}`, payload);
	}

	public deleteMessage(id: number): Observable<Object> {
		return this.baseApi.delete(`${UrlNames.getAllMessages}/${id}`);
	}

	public getValidEmails(payload: SendMessageApi): Observable<any> {
		payload = {
			...payload,
			userId: this.userId,
		};
		return this.baseApi.post(`${UrlNames.getValidEmailCount}`, payload);
	}
	public markMessageAsSeen(messageIds?: Array<number>): Observable<any> {
		if (!messageIds) {
			return this.baseApi.put(
				`${UrlNames.markMessageAsSeen}/${this.userId}`
			);
		}
		return this.baseApi.put(`${UrlNames.markMessageAsSeen}`, messageIds, {
			userId: this.userId,
		});
	}
}
