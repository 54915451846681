<mat-tab-group
	class="settings-tab-group my-4"
	mat-align-tabs="start"
	animationDuration="500ms"
>
	<mat-tab label="Product Access">
		<div class="container">
			<div
				class="row mat-elevation-z4 my-2 py-2 align-items-center rounded"
				*ngFor="let setting of productSettingType"
			>
				<div class="col-6">
					<h5>{{ setting.visibleName | translate }}</h5>
				</div>
				<div class="col-6">
					<div *ngIf="setting.type === 'checkbox'">
						<slr-toggle
							[checked]="setting.value == 0 ? false : true"
							(toggle)="slrCheckboxChange($event, setting)"
						></slr-toggle>
					</div>
					<div *ngIf="setting.type == 'radio'">
						<slr-rbtn-group
							[data]="setting.options"
							[fgName]="pdForm"
							[controlName]="setting.settingName"
						></slr-rbtn-group>
					</div>
					<div *ngIf="setting.type === 'text'">
						<slr-input
							[fgName]="pdForm"
							[controlName]="setting.settingName"
						></slr-input>
					</div>
					<div *ngIf="setting.type === 'select'">
						<slr-drp01
							[fgName]="pdForm"
							[controlName]="setting.settingName"
							[data]="setting.options"
							[bindValue]="'name'"
							[bindLabel]="'visibleName'"
						></slr-drp01>
					</div>
					<div *ngIf="setting.type === 'date'">
						<div class="col-12">
					<anniversary-date-input
						[fgName]="pdForm"
						[controlName]="setting.settingName"
						[label]="'Date'"
						[placeholder]="'MM-DD'"
					></anniversary-date-input>
						</div>
					</div>
					<div *ngIf="setting.type === 'file'">
						<div class="col-12">
							<ng-container
								*ngIf="
									uploadingFileFor == setting.settingName;
									else nonUploading
								"
							>
								<ng-container *ngIf="fileUploading">
									<slr-progress-bar
										[value]="fileUploadProgress"
									></slr-progress-bar>
								</ng-container>
							</ng-container>
							<ng-template #nonUploading>
								<ng-container
									*ngIf="
										pdForm.get(setting.settingName)?.value
									"
								>
									<div
										class="d-flex gap-2 align-items-center"
									>
										{{
											pdForm
												.get(setting.settingName)
												?.value.split("/")
												.pop()
										}}
										<mat-icon
											[matTooltip]="
												'DELETE' | translate | uppercase
											"
											class="small-icon cursor-pointer"
											(click)="
												removeFile(setting.settingName)
											"
											(keyup)="removeFile(setting.settingName)"
											>delete</mat-icon
										>
										<mat-icon
											[matTooltip]="'PREVIEW' | translate"
											class="small-icon cursor-pointer"
											(click)="
												previewFile(setting.settingName)
											"
											(keyup)="previewFile(setting.settingName)"
											>preview</mat-icon
										>
										<slr-btn01
											(click)="
												selectFile(setting.settingName)
											"
											(keyup)="selectFile(setting.settingName)"
											>{{
												"REPLACE_FILE" | translate
											}}</slr-btn01
										>
									</div>
								</ng-container>
								<ng-container
									*ngIf="
										!pdForm.get(setting.settingName)?.value
									"
								>
									<slr-btn01
										(click)="
											selectFile(setting.settingName)
										"
										(keyup)="
										selectFile(setting.settingName)
									"
										>{{
											"CHOOSE_FILE" | translate
										}}</slr-btn01
									>
								</ng-container>
							</ng-template>
						</div>
					</div>
					<div *ngIf="setting.type === 'color-picker'">
						<div class="col-6">
							<div class="d-flex gap-2 align-items-center">
								<slr-color-input
									[label]="'SELECT_COLOR' | translate"
									[fgName]="pdForm"
									[controlName]="setting.settingName"
								></slr-color-input>
								<mat-icon
									class="small-icon cursor-pointer"
									[matTooltip]="'RESET_COLOR' | translate"
									(click)="
										pdForm
											.get(setting.settingName)
											.setValue(
												defaultColors[
													setting.settingName
												]
											)
									"
									(keyup)="
									pdForm
										.get(setting.settingName)
										.setValue(
											defaultColors[
												setting.settingName
											]
										)
								"
									>format_color_reset</mat-icon
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</mat-tab>
</mat-tab-group>
<app-save-button
	class="m-3"
	(saveClicked)="saveSettings(false)"
></app-save-button>
<input
	#fileSelectInput
	hidden
	type="file"
	(change)="uploadFile($event)"
	accept="image/jpeg; image/png"
/>
