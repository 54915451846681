import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import {
	debounceTime,
	filter,
	distinct,
	map,
	Subject,
	take,
	takeUntil,
	withLatestFrom,
} from 'rxjs';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import {
	ChatUserInterface,
	ConversationInterface,
	ConversationModel,
	MessageInterface,
	SendMessageApi,
	TemporaryMessage,
} from 'src/app/modules/broadcast-and-messaging/_models/conversation.interface';
import { ChatService } from 'src/app/modules/broadcast-and-messaging/_services/chat.service';
import {
	addBroadCastMessage,
	addNewConversation,
} from 'src/app/modules/broadcast-and-messaging/_state/chat.actions';
import { ChatState } from 'src/app/modules/broadcast-and-messaging/_state/chat.reducer';
import { UserLocationAPIInterface } from 'src/app/modules/manage-users/_models/new-user-.model';
import { UserClassInterface } from 'src/app/modules/manage-users/_models/user-class.model';
import { ManageUserService } from 'src/app/modules/manage-users/_services/manage-user.service';
import { Group, LocationList, User } from '../../models/locations';
import { getLocationTreeFromServer } from '../../_state/shared.actions';
import {
	getCurrentChatUser,
	getLocationTree,
	getUserLocation,
	SharedState,
} from '../../_state/shared.reducer';
import { DropdownInterface } from '../dropdowns/slr-drp01/dropdown.model';
import {
	AuthenticationState,
	isLoggedIn,
} from 'src/app/modules/authentication/_state/authentication.reducer';

@Component({
	selector: 'app-new-conversation-popup',
	templateUrl: './new-conversation-popup.component.html',
	styleUrls: ['./new-conversation-popup.component.scss'],
})
export class NewConversationPopupComponent implements OnDestroy {
	public locations: LocationList;
	public locationData: Array<DropdownInterface>;
	public users: Array<User>;
	public userData: Array<DropdownInterface>;
	public availableIndividualUsers: Array<DropdownInterface> = [];
	public availableExcemptedUsers: Array<DropdownInterface> = [];
	public selectedIndividualUsers: Array<DropdownInterface> = [];
	public selectedExcemptedUsers: Array<DropdownInterface> = [];
	public combinedIndividualUsers: Array<DropdownInterface> = [];
	public combinedExcemptUsers: Array<DropdownInterface> = [];

	public groups: Array<Group>;
	public groupData: Array<DropdownInterface>;
	public userRoles: UserClassInterface[];
	public userRoleData: Array<DropdownInterface>;
	public sendMessageAsData: Array<DropdownInterface> = [
		{
			id: 1,
			name: 'ONLY_MESSAGE_BOARD',
			data: {
				selected: false,
			},
		},
		{
			id: 2,
			name: 'MESSAGE_BOARD_AND_EMAIL',
			data: {
				selected: true,
			},
		},
	];
	public isMobile: boolean = false;
	public newMessageForm: FormGroup;

	public validEmailCount: number = undefined;
	public newConversation: ConversationInterface;
	public isChildLocation: boolean = false;
	public today = new Date();
	private currentLocationId: number;
	private currentClientCode: string;
	private currentUser: ChatUserInterface;
	private unSubscriber$ = new Subject<void>();

	constructor(
		private sharedStore: Store<SharedState>,
		private authStore: Store<AuthenticationState>,
		private chatStore: Store<ChatState>,
		private dialogRef: MatDialogRef<NewConversationPopupComponent>,
		private notificationService: NotificationService,
		private localDataService: LocalDataService,
		private chatService: ChatService,
		private _fb: FormBuilder,
		private manageUserService: ManageUserService,
		private bpObs$: BreakpointObserver
	) {
		this.observeBreakpoiunts();
		this.sharedStore
			.pipe(
				select(getLocationTree),
				withLatestFrom(this.authStore.pipe(select(isLoggedIn)))
			)
			.subscribe({
				next: ([res, loggedIn]) => {
					if (!loggedIn) return;
					if (!res) {
						this.sharedStore.dispatch(getLocationTreeFromServer());
						return;
					}
					this.locationData = this.getLocationData(res);
				},
			});
		this.sharedStore.pipe(select(getCurrentChatUser)).subscribe({
			next: (res) => {
				this.currentUser = res;
				this.newConversation = new ConversationModel(
					new TemporaryMessage(this.currentUser)
				);
			},
		});
		this.newMessageForm = this._fb.group({
			locationIds: new FormControl([]),
			message: new FormControl(),
			expireDate: new FormControl(moment(new Date()).add(15, 'd').format('YYYY-MM-DD')),
			groupIds: new FormControl([]),
			roleIds: new FormControl([]),
			exemptUserIds: new FormControl([]),
			userIds: new FormControl([]),
			sendEmails: new FormControl(true),
		});
		this.sharedStore
			.pipe(
				select(getUserLocation),
				takeUntil(this.unSubscriber$),
				filter((value) => !!value || !!value.userList)
			)
			.subscribe({
				next: (res) => {
					this.locations = res;
					this.users = res.userList;
					this.groups =
						res.grandparentLocationList.groupList ??
						res.grandparentLocationList.parentLocationList[0]
							.groupList ??
						res.grandparentLocationList.parentLocationList[0]
							.childLocationList[0].groupList;
					this.initLocationType();
					this.initData();
				},
			});
		this.initNewConversation();
		this.checkFormChange();
	}

	private observeBreakpoiunts(): void {
		this.bpObs$
			.observe([Breakpoints.Small, Breakpoints.XSmall])
			.pipe(takeUntil(this.unSubscriber$))
			.subscribe({
				next: (res) => {
					this.isMobile = res.matches;
				},
			});
	}

	private checkFormChange(): void {
		this.newMessageForm.valueChanges
			.pipe(
				map((value: SendMessageApi) => {
					return { ...value, message: 'null' };
				}),
				distinct((value) => value),
				debounceTime(1500),
				takeUntil(this.unSubscriber$)
			)
			.subscribe({
				next: (res) => {
					this.getValidEmailCounts(res);
				},
			});
	}

	private getValidEmailCounts(object: SendMessageApi): void {
		if (
			!object?.userIds?.length &&
			!object?.groupIds?.length &&
			!object?.roleIds?.length
		)
			return;
		this.chatService.getValidEmails(object).subscribe({
			next: (res) => {
				this.validEmailCount = res.data.validUserAccout;
			},
		});
	}

	private initLocationType(): void {
		this.isChildLocation =
			this.localDataService.getCurrentLocationType() &&
			this.localDataService.getCurrentLocationType() == 'child';
	}

	private getLocationData(
		res: UserLocationAPIInterface
	): Array<DropdownInterface> {
		if (res.grandParent) {
			return [
				{
					id: res.grandParent.grandParentLocationId,
					name: res.grandParent.grandParentLocationName,
				},
				...res.grandParent.parents
					?.map((x) => {
						if (x.childs) {
							return [
								{
									id: x.parentLocationId,
									name: x.parentLocationName,
								},
								...x.childs.map((c) => {
									return {
										id: c.locationId,
										name: c.locationName,
									};
								}),
							];
						}
						return [
							{
								id: x.parentLocationId,
								name: x.parentLocationName,
							},
						];
					})
					.flat(),
			];
		}
		if (res.parent) {
			return [
				{
					id: res.parent.parentLocationId,
					name: res.parent.parentLocationName,
				},
				...res.parent.childs?.map((c) => {
					return {
						id: c.locationId,
						name: c.locationName,
					};
				}),
			];
		}
		return [
			{
				id: res.child?.locationId,
				name: res.child?.locationName,
			},
		];
	}

	private initData(): void {
		this.currentLocationId = this.localDataService.getLocationId();
		this.currentClientCode = this.localDataService.getSiteCode();
		if (this.isChildLocation && this.currentLocationId) {
			this.newMessageForm
				.get('locationIds')
				.setValue([this.currentLocationId]);
		}

		const allStaff = this.groups.find((x) => x.groupId === 1231);
		if (allStaff) {
			this.groupData = [
				{ id: allStaff.groupId, name: allStaff.groupName, data: null },
				...this.getGroupData(),
			];
		} else {
			this.groupData = this.getGroupData();
		}
		this.sortGroups();
		this.userData = this.users.map((x) => {
			return {
				id: x.userId,
				name: `${x.firstName} ${x.lastName ?? ''}`,
			};
		});
		this.availableIndividualUsers = [...this.userData];
		this.availableExcemptedUsers = [...this.userData];

		this.manageUserService
			.getUserClasses()
			.pipe(take(1))
			.subscribe({
				next: (res) => {
					this.userRoles = res;
					this.userRoleData = this.userRoles.map((x) => {
						return {
							id: x.userClassId,
							name: x.userClassName,
							data: null,
						};
					});
				},
			});
	}

	private sortGroups(): void {
		this.groupData.sort((group1, group2) => {
			const lgroup1 = group1.name.toLowerCase(),
				lgroup2 = group2.name.toLowerCase();
			if (lgroup1 < lgroup2) {
				return -1;
			}
			if (lgroup1 > lgroup2) {
				return 1;
			}
			return 0;
		});
	}

	private getGroupData(): Array<DropdownInterface> {
		return this.groups
			.filter((x) => x.groupId !== 1231)
			.sort((a, b) => {
				return a.groupName.localeCompare(b.groupName);
			})
			.map((x) => {
				return {
					id: x.groupId,
					name: x.groupName,
					data: null,
				};
			});
	}

	private initNewConversation(): void {
		this.chatStore.dispatch(
			addNewConversation({ conversation: this.newConversation })
		);
	}

	public onSendEmail(event: DropdownInterface): void {
		this.newMessageForm.get('sendEmails').setValue(event.id === 2);
	}

	public createNewConversation(event: MessageInterface): void {
		const messageObject: SendMessageApi = this.newMessageForm.value;

		if (
			(messageObject.userIds ?? []).length == 0 &&
			(messageObject.roleIds ?? []).length == 0 &&
			(messageObject.groupIds ?? []).length == 0
		) {
			this.notificationService.error(
				'Please select Users or Role or Group to send message to'
			);
			return;
		}

		if (!this.isChildLocation && messageObject.locationIds.length == 0) {
			this.notificationService.error(
				'Please select Locations to send message to'
			);
			return;
		}
		if (messageObject.message === null) {
			this.notificationService.error('Please Enter message to send');
			return;
		}
		this.chatService
			.sendMessage({
				...messageObject,
				clientCode: this.currentClientCode,
				locationId: this.currentLocationId,
			})
			.subscribe({
				next: (res) => {
					this.notificationService.success(res.message);
					if (res.data) {
						this.chatStore.dispatch(
							addBroadCastMessage({ message: res.data })
						);
					}
					this.close();
				},
			});
	}
	public close(): void {
		this.dialogRef.close();
	}

	ngOnDestroy(): void {
		this.unSubscriber$.next();
		this.unSubscriber$.unsubscribe();
	}

	assignUser(event: DropdownInterface) {
		if (event) {
			this.selectedIndividualUsers.push(event);
			this.availableExcemptedUsers = this.availableExcemptedUsers.filter(
				(user) => user.id !== event.id
			);
			this.availableIndividualUsers =
				this.availableIndividualUsers.filter(
					(user) => user.id !== event.id
				);
			this.triggerChangeDetection();
		}
	}

	removeUser(event: DropdownInterface) {
		if (event) {
			const index = this.selectedIndividualUsers.indexOf(event);
			if (index !== -1) {
				this.selectedIndividualUsers.splice(index, 1);
			}
			this.availableIndividualUsers.push(event);
			this.availableExcemptedUsers.push(event);
			this.triggerChangeDetection();
		}
	}

	exemptUser(event: DropdownInterface) {
		if (event) {
			this.selectedExcemptedUsers.push(event);
			this.availableIndividualUsers =
				this.availableIndividualUsers.filter(
					(user) => user.id !== event.id
				);
			this.availableExcemptedUsers = this.availableExcemptedUsers.filter(
				(user) => user.id !== event.id
			);
			this.triggerChangeDetection();
		}
	}

	removeExemptedUser(event: DropdownInterface) {
		if (event) {
			const index = this.selectedExcemptedUsers.indexOf(event);
			if (index !== -1) {
				this.selectedExcemptedUsers.splice(index, 1);
			}
			this.availableIndividualUsers.push(event);
			this.availableExcemptedUsers.push(event);
			this.triggerChangeDetection();
		}
	}
	triggerChangeDetection() {
		this.availableIndividualUsers = [...this.availableIndividualUsers];
		this.availableExcemptedUsers = [...this.availableExcemptedUsers];
		this.selectedIndividualUsers = [...this.selectedIndividualUsers];
		this.selectedExcemptedUsers = [...this.selectedExcemptedUsers];
	}
}
