<div class="col-12">
	<div class="modal-header align-items-center justify-content-center">
		<span class="modal-title">{{ "INACTIVATE" | translate }}</span>
	</div>
</div>
<div class="d-flex col-sm-12 justify-content-center">
	<div class="banner warning-banner p-2 col-sm-10 row">
		<div *ngIf="data.module === 'ALL_RESOURCES'">
			<div class="col-sm-1 ps-0 d-flex align-items-center">
				<i class="material-icons-round warning-icon">warning</i>
			</div>
			<div class="col-sm-11">
				{{ "INACTIVATE_WARNING" | translate }}
			</div>
		</div>
		<div *ngIf="data.module === 'DOCUMENT_MANAGER'">
			<div class="col-sm-1 ps-0 d-flex align-items-center">
				<i class="material-icons-round warning-icon">warning</i>
			</div>
			<div class="col-sm-11">
				{{ "INACTIVATE_DOCS_WARNING" | translate }}
			</div>
		</div>
	</div>
</div>
<div class="mt-3 course-actions">
	<div *ngIf="data.course">
		<div class="course-list py-2">
			<div class="col-11 ps-4">
				{{ data.course.name || data.course.learningPathwayName }}
				<app-badge
					class="ms-2"
					[badgeClass]="'assigned'"
					*ngIf="data.course.assigned"
				>
					{{ "ASSIGNED" | translate }}
				</app-badge>
			</div>
		</div>
	</div>
</div>
<div class="modal-footer justify-content-center">
	<button
		type="button"
		class="btn submit-btn"
		[disabled]="!data.course"
		(click)="inactivateResources()"
	>
		<span class="px-2">{{ "OK" | translate }}</span>
	</button>

	<button class="btn cancel-btn ms-2" (click)="closeModal()">
		{{ "CANCEL" | translate }}
	</button>
</div>
