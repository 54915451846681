import {
	Component,
	EventEmitter,
	OnInit,
	Output,
	Input,
	OnChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
	selector: 'app-search-box',
	templateUrl: './search-box.component.html',
	styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit, OnChanges {
	public searchForm: FormGroup = new FormGroup({});
	@Output() searchValue = new EventEmitter();
	@Input() placeHolderText = 'SEARCH';
	@Input() icon = 'search';
	@Input() cancelIcon = 'close';
	@Input() themeClass: 'lms' | 'policy' | 'qrm' = 'lms';
	@Input() showCancelIcon: boolean = false;
	@Input() hasBottomBorder: boolean = false;

	@Input() searchText = '';
	@Input() debouceTime = 300;
	constructor(private formBuilder: FormBuilder) {}
	ngOnInit(): void {
		this.searchForm = this.formBuilder.group({
			searchText: [''],
		});
		this.listenSearchChanges();
	}
	ngOnChanges(): void {
		if (this.searchForm?.get('searchText')) {
			this.searchForm?.setValue({ searchText: this.searchText });
		}
	}
	listenSearchChanges() {
		this.searchForm
			.get('searchText')
			?.valueChanges.pipe(
				debounceTime(this.debouceTime),
				distinctUntilChanged()
			)
			.subscribe((val) => this.searchValue.next(val));
	}

	clearInput(): void {
		this.searchForm?.get('searchText').setValue('');
	}

	public get _searchText(): string {
		return this.searchForm?.get('searchText')?.value || '';
	}
}
