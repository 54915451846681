import { Component, OnInit,Input } from '@angular/core';
import { Chart } from 'chart.js';
@Component({
  selector: 'app-multibar-chart',
  templateUrl: './multibar-chart.component.html',
  styleUrls: ['./multibar-chart.component.scss']
})
export class MultibarChartComponent implements OnInit {
  @Input() public Monthdata: any;
  @Input() public dataarray: any;
  dataArray: any;
  data: any;
  chart: any;
  quarter: string = 'Q1';
  q1: any = [
    {
      label: ' Enterprise Output - All Email users are current ',
      data: [62, 145, 95,45,78,24,47,24,86,24,57,47],
      backgroundColor: '#0066cc',
    },
    {
      label: 'LTC specific to an audit NURSING - LTC - Falls[nshn]',
      data: [45, 15, 45,65,68,44,67,46,35,56,46,80],
      backgroundColor: '#ff6666',
    },
    {
      label: 'PR',
      data: [106, 65,35,100,90,60,67,57,84,24,54,56],
      backgroundColor: '#c65353',
    },
    {
      label: 'ROI',
      data: [46, 15, 55,75,68,74,97,67,67,57,54,67],
      backgroundColor: '#990099',
    },
    {
      label: 'BOI',
      data: [107, 15,75,35,56,74,46,42,25,106,146,136],
      backgroundColor: '#330033',
    },
    {
      label: 'COI',
      data: [155, 135, 79,39,28,156,46,24,86,35,36,68],
      backgroundColor: '#cc3300',
    },
    {
      label: 'DOI',
      data: [135, 15, 65,55,88,94,17,94,106,146,135,147],
      backgroundColor: '#85B04B',
    },
    {
      label: 'NOI',
      data: [124, 135, 246,25,68,44,94,66,134,137,57],
      backgroundColor: '#b32d00',
    },
    {
      label: 'NOI',
      data: [135, 15, 105,145,148,14,157,144,14,14,167,127],
      backgroundColor: '#ffff00',
    },
    {
      label: 'POI',
      data: [62, 145, 95,45,78,24,47,24,86,24,57,47],
      backgroundColor: '#85B04B',
    },

    
  ];
  q2: any = [
    {
      label: 'IT',
      data: [62, 145, 95,45,78,24,47,24,86,24,57,47],
      backgroundColor: '#34568B',
    },
    {
      label: 'CR',
      data: [62, 145, 95,45,78,24,47,24,86,24,57,47],
      backgroundColor: '#FF6F61',
    },
    {
      label: 'PR',
      data: [62, 145, 95,45,78,24,47,24,86,24,57,47],
      backgroundColor: '#6B5B95',
    },
    {
      label: 'ROI',
      data: [62, 145, 95,45,78,24,47,24,86,24,57,47],
      backgroundColor: '#88B04B',
    },
  ];

  q3: any = [
    {
      label: 'IT',
      data: [62, 145, 95,45,78,24,47,24,86,24,57,47],
      backgroundColor: '#34568B',
    },
    {
      label: 'CR',
      data: [590, 300, 450],
      backgroundColor: '#FF6F61',
    },
    {
      label: 'PR',
      data: [575, 250, 705],
      backgroundColor: '#6B5B95',
    },
    {
      label: 'ROI',
      data: [700, 450, 1035],
      backgroundColor: '#88B04B',
    },
  ];
  q4: any = [
    {
      label: 'IT',
      data: [250, 750, 1055],
      backgroundColor: '#34568B',
    },
    {
      label: 'CR',
      data: [1105, 1075, 1040],
      backgroundColor: '#FF6F61',
    },
    {
      label: 'PR',
      data: [45, 29, 60],
      backgroundColor: '#6B5B95',
    },
    {
      label: 'ROI',
      data: [25, 45, 55],
      backgroundColor: '#88B04B',
    },
  ];


  ngOnInit() {
    this.getchart();
   
  }
  ngOnChanges(changes: any) {
    this.getchart();

  }

  getchart(){
    let options: any,
   ctx: any = document.getElementById('areaChart') as HTMLElement;
  ctx.style.backgroundColor = '#FFFFFF';
  
  this.loadData(null, this.quarter);

  this.data = {
    labels: this.Monthdata,
    datasets: this.dataArray,
  };

  options = {
    plugins: {
      legend: {
        display: true,
      },
    },
     
    responsive: true,

    layout: {
      padding: 15,
    },
    scales: {
      xAxes: [
        {
          barThickness: 10
      },
        {
          stacked: true,
          gridLines: {
            display: true,
          },
          ticks: {
            display: true,
            fontSize: 12,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: true,
          },
          ticks: {
            display: true,
            fontSize: 12,
          },
        },
      ],
    },
  };
  if (this.chart) this.chart.destroy();
  this.chart = new Chart(ctx, {
    type: 'bar',
    data: this.data,
    options: options,
  });
  }
  loadData(arr: any, span: any) {
    this.dataArray = [];
    this.quarter = span;
    if(arr === null) arr = this.q1;
    for (let key in arr) {
      if (arr.hasOwnProperty(key)) {
        this.dataArray.push(arr[key]);
      }
    }
    if (arr != null && this.data != undefined) {
      this.data.datasets = this.dataArray;
      this.chart.update();
    }
    console.log(this.dataArray);
  }

  // Refresh canvas dimensions
  onResize(event: any) {
    this.chart.render();
  }

}
