import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-session-expire-popup',
	templateUrl: './session-expire-popup.component.html',
})
export class SessionExpirePopupComponent {
	constructor(
		private matDialogRef: MatDialogRef<SessionExpirePopupComponent>
	) {}

	close() {
		this.matDialogRef.close();
	}
}
