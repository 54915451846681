import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, mergeMap, of, take } from 'rxjs';
import {
	logout,
	startTimer,
} from 'src/app/modules/authentication/_state/authentication.action';
import {
	AuthenticationState,
	isLoggedIn,
} from 'src/app/modules/authentication/_state/authentication.reducer';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private authStore: Store<AuthenticationState>,
	) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| boolean
		| UrlTree
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree> {
		return this.authStore.pipe(
			select(isLoggedIn),
			take(1),
			mergeMap((loggedIn) => {
				if (!loggedIn) {
					this.authStore.dispatch(
						logout({ redirectUri: state.url, redirect: true })
					);
					return of(false);
				}
				this.authStore.dispatch(startTimer());
				return of(true);
			})
		);
	}
}
