import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DirTableInterface } from 'src/app/shared/models/dir-table.interface';

@Component({
	selector: 'slr-dir-table',
	templateUrl: './slr-dir-table.component.html',
	styleUrls: ['./slr-dir-table.component.scss'],
})
export class SlrDirTableComponent {
	@Input() public data: DirTableInterface[];
	@Input() public tableHeader: string;
	@Output() public selectionChange = new EventEmitter<number[]>();
	@Output() public onRowClick = new EventEmitter<number>();

	public selection = new SelectionModel<number>(true);
	public catSortDir: string = '';
	public activePanelIds: string[] = [];

	panelChange(event: any) {
		event.nextState
			? this.activePanelIds.push(event.panelId)
			: this.removeActivePanel(event.panelId);
	}

	removeActivePanel(id: string) {
		this.activePanelIds.splice(this.activePanelIds.indexOf(id), 1);
	}

	public onSelect(id: number): void {
		this.selection.toggle(id);
		this.selectionChange.emit(this.selection.selected);
	}

	public _onRowClick(id: number): void {
		this.onRowClick.emit(id);
	}	
}
