import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
	DateAdapter,
	MAT_DATE_FORMATS,
	MAT_DATE_LOCALE,
} from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { SLR_DATE_FORMAT } from 'src/app/core/directives/date-input-format.directive';
import { ValidatorMessage } from 'src/app/core/models/validation-messages.model';
import { ErrorMessageService } from 'src/app/core/services/error-message.service';

@Component({
	selector: 'slr-date-input',
	templateUrl: './slr-date-input.component.html',
	styleUrls: ['./slr-date-input.component.scss'],
	providers: [
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE],
		},
		{
			provide: MAT_DATE_FORMATS,
			useValue: SLR_DATE_FORMAT,
		},
	],
})
export class SlrDateInputComponent implements OnInit {
	@Input() public label: string;
	@Input() public fgName: FormGroup;
	@Input() public controlName: string;
	@Input() public placeholder: string;
	@Input() public width: string = '100%';
	@Input() public startView: 'month' | 'year' | 'multi-year' = 'month';
	@Input() public min: Date | string = '';
	@Input() public max: Date | string = '';
	@Input() public validatorMessages: ValidatorMessage[];
	@Output() public matDateChanged = new EventEmitter();

	public defaultValidatorMessages: ValidatorMessage[];

	public errorMessage: string;

	constructor(
		private errorMessageService: ErrorMessageService,
		private trasnlateService: TranslateService
	) {}

	ngOnInit(): void {
		this.defaultValidatorMessages = [
			{
				validationType: 'required',
				validationMessage: `${this.trasnlateService.instant(
					this.placeholder ?? 'THIS_FIELD'
				)} ${this.trasnlateService.instant('IS_REQUIRED_GENERAL')}`,
			},
			{
				validationType: 'matDatepickerParse',
				validationMessage:
					this.trasnlateService.instant('WRONG_FORMAT'),
			},

			...(this.validatorMessages ?? []),
		];
		this.setErrorMessage();
	}

	public setErrorMessage(): void {
		this.errorMessage = this.errorMessageService.setErrorMessage(
			this.defaultValidatorMessages,
			this.fgName.get(this.controlName)
		);
	}
	public onChange() {
		console.log(this.fgName);
	}
	matDateChange(event) {
		this.matDateChanged.emit(event);
	}
}
