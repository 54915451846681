import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { BroadcastMessageInterface } from 'src/app/modules/broadcast-and-messaging/_models/conversation.interface';

@Component({
	selector: 'app-thread-card',
	templateUrl: './thread-card.component.html',
	styleUrls: ['./thread-card.component.scss'],
})
export class ThreadCardComponent implements OnInit {
	@Input() public conversation: BroadcastMessageInterface;
	@Input() public isOnPopup: boolean = false;
	public isSelected: boolean;
	public lastMessage: string;
	public lastMessageTime: string;
	public name: string;
	public imgPath: string;

	ngOnInit(): void {
		this.intiThreadData()
	}

	private intiThreadData(): void {
		this.lastMessage = this.conversation.messageInfo
		this.lastMessageTime = moment(this.conversation.createDate).format('DD-MM')
		this.name = this.conversation.created_by_name ?? ''
	}
}
