import {
	Component,
	Inject,
	OnDestroy,
	OnInit,
	Output,
	EventEmitter,
} from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { LocalDataService } from 'src/app/core/services/local-data.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UserComment } from 'src/app/modules/skills-and-credentials/_models/skilled-users.model';
import { SkillsService } from 'src/app/modules/skills-and-credentials/_services/skills.service';
import { UserProfileService } from 'src/app/modules/user-profile/_services/user-profile.service';

@Component({
	selector: 'app-add-comment-popup',
	templateUrl: './add-comment-popup.component.html',
	styleUrls: ['./add-comment-popup.component.scss'],
})
export class AddCommentPopupComponent implements OnInit, OnDestroy {
	public commentForm: FormGroup;
	private numberOfComments: number = 0;
	private unsubscribe$ = new Subject();
	@Output() updateList = new EventEmitter();
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private _fb: FormBuilder,
		private profileService: UserProfileService,
		private dialogRef: MatDialogRef<AddCommentPopupComponent>,
		private skillsService: SkillsService,
		private localData: LocalDataService,
		private notificationService: NotificationService
	) {}

	ngOnInit(): void {
		this.numberOfComments =
			this.data && this.data.comments && this.data.comments.length;
		this.initForm();
	}
	ngOnDestroy(): void {
		this.unsubscribe$.next(true);
	}
	private initForm(): void {
		this.commentForm = this._fb.group({
			comment: new FormControl('', [Validators.required]),
		});
	}

	public onSubmit(): void {
		if (this.isFromBulkAdd()) {
			this.addSkillUserComment();
			return;
		}
	}
	private addSkillUserComment(): void {
		this.skillsService
			.addSkillComment(this.data, {
				comment: this.commentForm.value.comment,
				updatedBy: this.localData.getUserId(),
				updateDate: '',
				uploadedBy: '',
				commentId: null,
			})
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe({
				next: () => {
					this.notificationService.success(
						'Comment added successfully'
					);
					this.updateCommentList();
				},
				error: (err) => {
					this.notificationService.error(err);
				},
			});
	}
	public updateComment(comment: string): void {
		this.commentForm.patchValue({
			comment: comment ?? '',
		});
	}
	public closeModal(): void {
		this.dialogRef.close({ numberOfComments: this.numberOfComments });
	}
	public deleteComment(comment: UserComment) {
		this.skillsService
			.deleteUserSkillComment(comment.commentId)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe({
				next: () => {
					this.notificationService.success('Comment Deleted');
					if (this.isFromBulkAdd()) {
						if (this.data.userId) this.updateCommentList();
					}
				},
				error: (err) => {
					this.notificationService.error(err);
				},
			});
	}
	private isFromBulkAdd(): boolean {
		return this.data && this.data.userId && this.data.skillId;
	}
	private updateCommentList(): void {
		const { skillId, userId } = this.data;
		this.skillsService
			.getUserSkillComments({ skillId, userId })
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe({
				next: (res) => {
					this.data['comments'] = res[0]?.userComments || [];
					this.numberOfComments = this.data['comments'].length;
				},
				error: (err) => this.notificationService.error(err),
			});
	}
}
