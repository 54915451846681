import { createAction, props } from '@ngrx/store';
import { SLRUser } from '../_models/users.interface';

export enum ManageUsersActionTypesEnum {
	GET_USERS = '[Manage Users] get users',
	SAVE_USERS = '[Manage Users] save users',
	GENERTE_ADJECENT_USER_ID = '[Manage Users] generate adjecent User Ids',
	SAVE_ADJECENT_USER_ID = '[Manage Users] save adjecent User Id',
	SHOW_DEFAULT_PASSWORD_FLAG = '[Manage Users] updateShowDefaultPasswordFlag',
}

export const getUsers = createAction(
	ManageUsersActionTypesEnum.GET_USERS,
	props<{ activeUserId: number }>()
);
export const saveUsers = createAction(
	ManageUsersActionTypesEnum.SAVE_USERS,
	props<{ users: SLRUser[] }>()
);
export const generateAdjecentUserId = createAction(
	ManageUsersActionTypesEnum.GENERTE_ADJECENT_USER_ID,
	props<{ userId: number }>()
);

export const saveAdjecentUserId = createAction(
	ManageUsersActionTypesEnum.SAVE_ADJECENT_USER_ID,
	props<{ previousUserId: number; nextUserId: number }>()
);

export const updateShowDefaultPasswordFlag = createAction(
	ManageUsersActionTypesEnum.SHOW_DEFAULT_PASSWORD_FLAG,
	props<{ value: boolean }>()
);
