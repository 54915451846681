import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Toolbar, Editor, ToolbarCustomMenuItem } from 'ngx-editor';
import { EditorState, Plugin, PluginKey, Transaction } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { take } from 'rxjs';
import { BroadcastService } from 'src/app/core/services/broadcast.service';
import { ImagePropertiesPopupComponent } from 'src/app/shared/components/inputs/slr-ngx-editor/image-properties-popup/image-properties-popup.component';

@Component({
	selector: 'slr-ngx-editor',
	templateUrl: './slr-ngx-editor.component.html',
	styleUrls: ['./slr-ngx-editor.component.scss'],
})
export class SlrNgxEditorComponent implements OnInit {
	@Input() public formName: FormGroup;
	@Input() index: number;
	@Input() controlName: string;
	@Input() placeholder: string = 'Type Here';
	@Input() label: string;

	// @Input() set index(value: number) {
	// 	this.formName = (this._fd.form.controls['questionList'] as FormArray)
	// 		.controls[value] as FormGroup;
	// }
	public editor: Editor;
	public htmlContent = '<p></p>';
	public toolbar: Toolbar = [
		['bold', 'italic'],
		['underline', 'strike'],

		['ordered_list', 'bullet_list'],

		// ['image'],
	];
	public isActive = false;
	public isDisabled = false;

	constructor(
		private dialog: MatDialog,
		private _fd: FormGroupDirective,
		private broadcastService: BroadcastService
	) {}

	ngOnInit(): void {
		this.initEditor();
	}

	private initEditor(): void {
		this.editor = new Editor({
			attributes: {
				height: '250px',
			},
		});
		const plugin = new Plugin({
			key: new PluginKey(`custom-menu-codemirror`),
			view: () => {
				return {
					update: this.update,
				};
			},
		});

		this.editor.registerPlugin(plugin);
		if (this.formName && this.formName.get(this.controlName).value) {
			this.htmlContent = this.formName.get(this.controlName).value;
		}
	}
	private update = (view: EditorView) => {};

	private execute(
		state: EditorState,
		data: any,
		dispatch?: (tr: Transaction) => void
	): boolean {
		return this.editor.commands
			.insertImage(data.url)
			.align(data.alignment.toLowerCase())
			.focus()
			.scrollIntoView()
			.exec();
	}

	uploadImage(event: any) {
		event.preventDefault();
		const { state, dispatch } = this.editor.view;
		// this.execute(state, dispatch);
		this.dialog
			.open(ImagePropertiesPopupComponent, {
				width:
					this.broadcastService.screenSize.value === 'small'
						? '100%'
						: '40%',
			})
			.afterClosed()
			.pipe(take(1))
			.subscribe({
				next: (res) => {
					if (res && res.url) {
						this.execute(state, res, dispatch);
					}
				},
			});
	}
}
