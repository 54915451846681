import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
	debounceTime,
	map,
 	Subject,
	takeUntil,
} from 'rxjs';
import { MultiSelectChkbInterface } from './_models/multi-select-chkb.model';

@Component({
	selector: 'multi-select-chkb',
	templateUrl: './multi-select-chkb.component.html',
	styleUrls: ['./multi-select-chkb.component.scss'],
})
export class MultiSelectChkbComponent implements OnInit, OnChanges {
	@Input() public title: string;
	@Input() public data: Array<MultiSelectChkbInterface<any>>;
	@Input() public filterable: boolean = true;
	@Input() public hasPadding: boolean = true;
	@Input() public disabled: boolean = false;
	@Output() public onSelectionChange = new EventEmitter<
		SelectionChange<number>
	>();
	@Output() public onItemAdded = new EventEmitter<Array<number>>();
	@Output() public onItemRemoved = new EventEmitter<Array<number>>();
	public filteredData: Array<MultiSelectChkbInterface<any>>;
	public seeLess = false;
	public showSeeLessButton = true;
	public searchForm: FormGroup;
	public selection: SelectionModel<number> = new SelectionModel(true, []);
	private unsubscriber$ = new Subject<void>();

	constructor(private _fb: FormBuilder) {
		this.searchForm = this._fb.group({
			search: new FormControl(''),
		});

		this.selection.changed.pipe(debounceTime(500)).subscribe((x) => {
			this.onSelectionChange.emit(x);
		});
	}

	public onSelectParent(id: number, data: any): void {
		this.selection.toggle(id);
		data?.forEach((x: MultiSelectChkbInterface<any>) => {
			if (x.data) {
				this.onSelectParent(x.id, x.data);
				return;
			}
			this.selection.toggle(x.id);
		});
	}

	public onSelect(id: number): void {
		this.selection.toggle(id);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes && changes['data']) {
			this.updateData();
		}
	}

	ngOnInit(): void {
		this.updateData();
		this.searchForm.valueChanges
			.pipe(
				takeUntil(this.unsubscriber$),
				map((x) => {
					return x['search'];
				})
			)
			.subscribe({
				next: (q: string) => {
					this.showSeeLessButton = q.length == 0;
					if (q.length == 0) {
						this.seeLess = true;
						this.updateData();
						return;
					}
					this.seeLess = false;
					this.filteredData = this.data.filter((x) =>
						x.name.toLowerCase().includes(q.toLowerCase())
					);
				},
			});
	}

	public updateData(): void {
		this.filteredData = this.seeLess ? this.data?.slice(0, 3) : this.data;
	}
	public onToggle(checked: boolean): void {
		if (checked) {
			this.data.forEach((x) => {
				this.selection.select(x.id);
			});
		} else {
			this.selection.clear();
		}
	}
}
