import {
	Component,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ExportToCsv } from 'export-to-csv';
import { jsPDF } from 'jspdf';
import autoTable, { RowInput } from 'jspdf-autotable';
import { Subject, takeUntil } from 'rxjs';
import { getBase64Logo } from 'src/app/core/services/getBase64logo';
import { CustomTableColumn } from 'src/app/modules/reports/_models/reports-overview.model';
import { ExportReportsService } from 'src/app/modules/reports/_services/export-reports.service';
import { DateFormatPipe } from '../../directives/date-format.pipe';
import { exportCsvOptions } from './export-data.options';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterDatasService } from 'src/app/modules/reports/_services/master-datas.service';
import { AppConstants } from '../../constants/app-constants';
@Component({
	selector: 'app-export-data',
	templateUrl: './export-data.component.html',
	styleUrls: ['./export-data.component.scss'],
})
export class ExportDataComponent implements OnChanges, OnDestroy, OnInit {
	@Input() headerColumns: Array<CustomTableColumn> = [];
	@Input() tableData: Array<any> = [];
	@Input() public customTable: boolean = false;
	@Input() fileName: string = 'User dashboard report';
	@Output() downloadFile: Subject<'pdf' | 'csv'> = new Subject();
	@Input() equalSizeColumns: boolean = false;
	@Input() compliant: number = 0;
	private tableContents: Array<any> = [];
	private options = exportCsvOptions;
	private unsubscribe$ = new Subject();
	private columnNames: Array<string> = [];
	private logo: string = '';
	constructor(
		private translate: TranslateService,
		private dateFormataPipe: DateFormatPipe,
		private exportReportService: ExportReportsService,
		private route: ActivatedRoute,
		private masterdataService: MasterDatasService,
		private router: Router,
		private translatePipe: TranslatePipe
	) {}
	ngOnInit(): void {
		getBase64Logo().then((rslt: string) => (this.logo = rslt));
	}
	ngOnChanges(): void {
		this.getTableHeaders();
		this.tableContents = this.getTableContents();
	}
	private getVisibleColumns(): Array<CustomTableColumn> {
		return this.headerColumns.filter((column) => column.isShown);
	}
	private getTableHeaders(): void {
		this.columnNames = this.getVisibleColumns().map(
			(column) => column.columnName
		);
		this.columnNames.forEach((key) =>
			this.translate
				.get(key)
				.pipe(takeUntil(this.unsubscribe$))
				.subscribe({
					next: (res) => {
						this.columnNames[this.columnNames.indexOf(key)] = res;
					},
				})
		);
	}
	private getTableContents(): any {
		return this.tableData.map((row) => {
			const rowData = [];
			this.getVisibleColumns().forEach((column) => {
				row[column.bindKey]
					? rowData.push(
							column.dateValue
								? column.bindKey == 'latestLogin'
									? row[column.bindKey]
									: this.getFormattedDate(row[column.bindKey])
								: row[column.bindKey].toString()
					  )
					: rowData.push(column.numberColumn ? 0 : '-');
			});
			return rowData;
		});
	}
	private getFormattedDate(date: string): string {
		return this.dateFormataPipe.transform(date);
	}
	ngOnDestroy(): void {
		this.unsubscribe$.next(true);
	}
	public downloadCsvFile(): void {
		if (this.customTable) {
			this.downloadFile.next('csv');
			return;
		}
		let csvExporter = new ExportToCsv({
			...this.options,
			headers: this.columnNames,
			filename: this.fileName,
		});
		if (!this.tableContents || !this.tableContents.length) {
			const emptyData = this.columnNames.reduce((acc, header) => {
				acc[header] = '';
				return acc;
			}, {});
			csvExporter.generateCsv([emptyData]);
			return;
		}
		csvExporter.generateCsv(this.tableContents);
		csvExporter = null;
	}
public async downloadPdfFile(): Promise<void> {
	const params = this.route.snapshot.queryParams;
	const {
	  completedStatus,
	  educationalYear,
	  orientationMode,
	  supervisorId,
	  previousYear,
	  userGroups,
	} = params;
  
	if (this.customTable) {
	  this.downloadFile.next('pdf');
	  return;
	}
  
	//const doc = new jsPDF();
	const doc = new jsPDF('landscape');
	let pdfBody: RowInput[] = [];
  
	let completedStatusText = '';
	switch (completedStatus) {
	  case '-1':
		completedStatusText = this.getTranslatedContent('COMPLETION_STATUS_ALL');
		break;
	  case '1':
		completedStatusText = this.getTranslatedContent('COMPLETION_STATUS_COURSES_COMPLETED');
		break;
	  case '2':
		completedStatusText = this.getTranslatedContent('COMPLETION_STATUS_COURSES_OVERDUE');
		break;
	  case '3':
		completedStatusText = this.getTranslatedContent('COMPLETION_STATUS_COURSES_OUTSTADING');
		break;
	  case '4':
		completedStatusText = this.getTranslatedContent('COMPLETION_STATUS_NO_COURSES_COMPLETED');
		break;
	  default:
		completedStatusText = this.getTranslatedContent('COMPLETION_STATUS_ALL');
	}
	let orientationModeText = '';
	switch (orientationMode) {
	  case '-1':
		orientationModeText =  this.getTranslatedContent('ORIENTATION_MODE_ALL');
		break;
	  case '0':
		orientationModeText = this.getTranslatedContent('ORIENTATION_MODE_NO');
		break;
	  case '1':
		orientationModeText =this.getTranslatedContent('ORIENTATION_MODE_YES');
		break;
	  default:
		orientationModeText =  this.getTranslatedContent('ORIENTATION_MODE_ALL');
	}
	let supervisorFullName = await this.getSupervisorFullName(supervisorId);
	let educationGroupsText = await this.getEducationGroups(userGroups);
	const filters = [
	  completedStatusText,
	  `Education Year: ${educationalYear || new Date().getFullYear()}`,
	  orientationModeText,
	  `Supervisor: ${supervisorFullName}`,
	  `Include Last Year's Incomplete: ${previousYear == 1 || !previousYear ? 'Yes' : 'No'}`,
	  `Education Groups: ${educationGroupsText}`,
	];
	
	let currentY = 40;
	const maxWidth = doc.internal.pageSize.width - 200; 
	// Handle filters
	if(this.router.url.includes('dashboard-report')) {
		doc.setFontSize(16);
	doc.text(this.getTranslatedContent('DASHBOARD_REPORT'), 15, 30);
	doc.setFontSize(12);
	filters.forEach((filter) => {
	  const splitText = doc.splitTextToSize(filter, maxWidth);
	  doc.text(splitText, 20, currentY);
	  currentY += splitText.length * 6;
	});
  
	const spaceAfterFilter = 5;
	currentY += spaceAfterFilter; 
	// Insert Doughnut Chart Image
	const graph = document.getElementById(`doughnut-chart-container-1`);
	const canvas = <HTMLCanvasElement>graph.childNodes[0];
	const url = canvas.toDataURL('image/png');
  
	doc.setFontSize(14);
	const chartX = 150;
	const chartY = 30;
	const chartWidth = 50; 
	const chartHeight = 50; 
  
	// Add the chart to the PDF
	doc.addImage({
	  imageData: url,
	  format: 'PNG',
	  x: chartX,
	  y: chartY,
	  width: chartWidth,
	  height: chartHeight,
	});
  
	doc.setFillColor(47, 104, 183); 
	doc.rect(chartX + 58, chartY + 4, 5, 5, `F`); 
	doc.setFillColor(232, 240, 249); 
	doc.rect(chartX + 58, chartY + 12, 5, 5, `F`);
	
	const compliant = isNaN(this.compliant) ? 0 : this.compliant; 
	const notCompliant = 100 - compliant;
	doc.text(`${this.getTranslatedContent('COMPLIANT')}: ${compliant}%`, chartX + 65, chartY + 7);
	doc.text(`${this.getTranslatedContent('NOT_COMPLIANT')}: ${notCompliant.toFixed(2)}%`, chartX + 65, chartY + 17);
	currentY = chartY + chartHeight + 20;
}
	let startY = currentY;
	const cellWidth = (doc.internal.pageSize.width - 20) / this.columnNames.length;
	const minCellWidth = cellWidth > 20 ? 20 : cellWidth;
  
	this.tableContents.forEach((row) => {
	  pdfBody.push(
		row.map((value) => {
		  return {
			content: value,
			styles: {
			  ...(this.equalSizeColumns ? { cellWidth } : { minCellWidth }),
			},
		  };
		})
	  );
	});
  
	autoTable(doc, {
	  head: [this.columnNames],
	  body: pdfBody,
	  startY: startY,
	  styles: {
		...(this.equalSizeColumns && { fontSize: 8 }),
	  },
	  ...this.exportReportService.getDefaultAutotableStyles(),
	  showHead: 'everyPage',
	});
  
	this.exportReportService.addPdfHeaders(doc);
	doc.setProperties({ title: this.fileName });
	this.exportReportService.openDocInNewTab(doc, this.fileName);
  }

	private async getSupervisorFullName(supervisorId: string): Promise<string> {
		if (supervisorId) {
			try {
				const res = await this.masterdataService
					.getSupervisors()
					.toPromise();
				const supervisor = res.find(
					(sup) => sup.userId === Number(supervisorId)
				);
				return supervisor ? supervisor.fullName : 'All';
			} catch (error) {
				console.error('Error fetching supervisor:', error);
				return 'All';
			}
		} else {
			return 'All';
		}
	}
	private async getEducationGroups(userGroups: string): Promise<string> {
		if (userGroups) {
			console.log(userGroups);
			try {
				const res = await this.masterdataService
					.getEducationGroups()
					.toPromise();
				console.log(res);
				const groupNames = res
					.filter((group) =>
						userGroups
							.split(',')
							.includes(group.educationGroupId.toString())
					)
					.map((group) => group.educationGroupName);
				return groupNames.length ? groupNames.join(', ') : 'All';
			} catch (error) {
				console.error('Error fetching user groups:', error);
				return 'All';
			}
		} else {
			return 'All';
		}
	}
	private getTranslatedContent(content: string): string {
		return this.translatePipe.transform(content);
	}
} 