import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-pwd-link-expired-popup',
	templateUrl: './pwd-link-expired-popup.component.html',
	styleUrls: ['./pwd-link-expired-popup.component.scss'],
})
export class PwdLinkExpiredPopupComponent implements OnInit {
	constructor(
		private matDialogRef: MatDialogRef<PwdLinkExpiredPopupComponent>
	) {}

	ngOnInit(): void {}

	public close(data: boolean): void {
		this.matDialogRef.close(data);
	}
}
