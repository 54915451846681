import { InjectionToken } from '@angular/core';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import {
	authenticationReducer,
	AuthenticationState,
	initialAuthState,
} from '../modules/authentication/_state/authentication.reducer';
import {
	intialMyCourseState,
	myCourseReducer,
	MyCourseState,
} from '../modules/my-courses/_state/my-course.reducer';
import {
	initialUserProfileState,
	userProfileReducer,
	UserProfileState,
} from '../modules/user-profile/_state/user-profile.reducer';
import {
	initialSharedState,
	sharedReducer,
	SharedState,
} from '../shared/_state/shared.reducer';

import { AppTypes } from './app.action';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>(
	'root reducer'
);
export interface AppState {
	authentication: AuthenticationState;
	shared: SharedState;
	userProfile: UserProfileState;
	myCourse: MyCourseState;
}

export const initialAppState: AppState = {
	authentication: initialAuthState,
	shared: initialSharedState,
	userProfile: initialUserProfileState,
	myCourse: intialMyCourseState,
};

export const reducers: ActionReducerMap<AppState> = {
	authentication: authenticationReducer,
	shared: sharedReducer,
	userProfile: userProfileReducer,
	myCourse: myCourseReducer,
};

export function clearStateMetaReducer(reducer: any) {
	return function (state, action) {
		if (action.type === AppTypes.ResetAppState) {
			return reducer(undefined, action)
		}
		return reducer(state, action);
	};
}

export const metaReducers: MetaReducer<AppState>[] = [];
